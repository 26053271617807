import CryptoJS from 'crypto-js';
import pako from 'pako';

let uek = '';

export const setUEKValue = (uekValue) => {
  uek = uekValue;
};

export const setLocalStorageItem = (key, value) => {

  if (uek) {
    const compressedValue = compressData(value); // Compress the data
    
    const encryptedValue = CryptoJS.AES.encrypt(compressedValue, uek).toString();
    localStorage.setItem(CryptoJS.MD5(key), encryptedValue);
  } else {
    localStorage.setItem(key, value);
  }
};

export const getLocalStorageItem = (key) => {
  if (uek) {
    const item = localStorage.getItem(CryptoJS.MD5(key));
    if (item) {
      const decryptedValue = CryptoJS.AES.decrypt(item, uek).toString(CryptoJS.enc.Utf8);
      return decompressData(decryptedValue); // Decompress the data
    }
    return null;
  }
  return localStorage.getItem(key);
};

// Compresses data using pako.gzip
const compressData = (data) => {
  const uncompressedData = pako.gzip(data);
  const compressedData = Array.from(uncompressedData).map(byte => String.fromCharCode(byte)).join('');
  return compressedData;
};

// Decompresses data using pako.ungzip
const decompressData = (compressedData) => {
  const compressedByteArray = compressedData.split('').map(char => char.charCodeAt(0));
  const compressedUint8Array = new Uint8Array(compressedByteArray);
  const uncompressedData = pako.ungzip(compressedUint8Array, { to: 'string' });
  return uncompressedData;
};