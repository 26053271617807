import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import 'simplebar/dist/simplebar.min.css';
import './Scrollbar.css';
import debounce from 'awesome-debounce-promise';
import Session from './Data/Session/Session.js';
import { cancelPreviousRequest } from '../services/agent';

//Services
import * as ProgramService from '../services/ProgramService';
import * as TargetService from '../services/TargetService'

//Components
import Sidebar from './Sidebar/Sidebar';
import MobileSidebar from './Sidebar/MobileSidebar';
import Header from './Header';
import AdminHeader from './AdminHeader';
import SnackbarComponent from '../assets/snackbar/Snackbar';
import GlobalDialog from '../assets/dialog/GlobalDialog';

import UserSettingHeader from '../components/UserSetting/UserSettingHeader';

//Store Data
import { setTargets, setAll, setMemos } from '../store/data/actions';
import { setPatient, getPatients } from '../store/patient/actions';
import { setSelection, setSnackbar, setTargetLoading, setLoading, setSidebarWidth as setSidebarWidthRedux, setIsMobile } from '../store/general/actions';
import { tokenLogin, logout } from '../store/auth/actions';
import { connect } from 'react-redux';
import EditDomain from './Program/EditDomain';
import NewProgram from './Program/NewProgram';
import UpdateSubscription from './Organization/UpdateSubscription';
import { updateDomain, deleteDomain, archiveDomain } from '../services/DomainService';
import UploadDialog from '../assets/dialog/UploadDialog';
import { listMemo } from '../services/MemoService';
import { CanUserFunc } from './CanUser';
import { access } from '../constants';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import LibrarySidebar from './Library/LibrarySidebar';
import ZendeskDialog from '../assets/dialog/ZendeskDialog';

const defaultDrawerWidth = 240;

const useStyles = makeStyles(theme => ({
    content: {
		// maxHeight: 'calc(100vh - 73px)',
		// height: 'calc(100vh - 73px)',
		// overflow: 'auto !important',
    },
	adminContent: {
		marginTop: '65px',
		'@media (max-width: 768px)': {
            marginTop: '30px',
        }
	}
}));

const BaseLayout = (props) => {
	const classes = useStyles();
	const [sidebarWidth, setSidebarWidth] = useState(null);
	const [editDomainOpen, setEditDomainOpen] = useState(false);
	const [selectedDomain, setSelectedDomain] = useState(null);
	const [editProgramOpen, setEditProgramOpen] = useState(false);
	const [selectedProgram, setSelectedProgram] = useState(null);
	const [showArchived, setShowArchived] = useState(false);
	const [sideBarOpen, setSideBarOpen] = useState(false); 

	// this is updated during resizing
	const [updatingWidth, setUpdatingWidth] = useState(0);

	const firstLoad = useRef(true);
	
	props.setIsMobile(useMediaQuery('(max-width: 768px)'));

	useEffect(() => {
		if (!props.user.id && props.token) {
			props.tokenLogin();
		} else if (!props.user.id && !props.token) {
			props.logout();
		}
	}, []);

	useEffect(() => {
		if (!props.patient?.id && props.user.id) {
			props.getPatients();
		}

	}, [props.user]);

	useEffect(() => {
		const drawerWidth = localStorage.getItem('DrawerWidth');
		if (drawerWidth) {
			setSidebarWidth(parseInt(drawerWidth, 10));
		} else {
			setSidebarWidth(defaultDrawerWidth);
		}
	}, [])

	// fetch full data when patient changes
	// or show archived changed
	useEffect(() => {
		if (props.patient && props.patient.id) {
			fetchFullData(props.patient.id, !firstLoad.current);
			CanUserFunc(access.notes.view) && fetchMemo(props.patient.id);
			firstLoad.current = false;
		}
	}, [props.patient]);

	const debouncedSetSidebarWidth = debounce(props.setSidebarWidthRedux, 1000);
	useEffect(() => {
		debouncedSetSidebarWidth(sidebarWidth);
	}, [sidebarWidth])

	const fetchFullData = (patientId, all = true, customSelection = null) => {
		let selection;
		if (all) {
			selection = { ...props.selection, domain: 'all', program: 'all', all: true, patient: patientId };
		} else {
			selection = customSelection || props.selection;
		}

		selection = { ...selection, patient: patientId };

		props.setTargetLoading(true);
		props.setSelection(selection, selection.target);
		props.setLoading('getDomains');
		ProgramService.getFullDataByPatientId(
			selection, 
			patientId,
			[
				cancelPreviousRequest('getFullDataByPatientId_fetchFullData_0'),
				cancelPreviousRequest('getFullDataByPatientId_fetchFullData_1')
			]
		).then(res => {
			props.setAll(res);
			props.setTargetLoading(false);
			props.setLoading('getDomains', false);
		});
	}

	const fetchMemo = (patientId) => {
		listMemo(patientId).then((res) => {
			props.setMemos(res.data.data);
		})
	}

	const updateShowArchived = (showArchived) => {
		fetchFullData(props.patient.id, false, { ...props.selection, showArchived });
	}

	/**
	 * 
	 * @param item: Item providing basis of filteration
	 * Filters the grid data
	 */
	const filterByItem = item => {
		if (!props.selection.patient) {
			return;
		}

		props.setTargetLoading(true);
		if (item.all) {
			fetchFullData(props.patient.id);
		} else {
			props.setSelection({ ...props.selection, ...item });
			TargetService.getFilteredTargets({ ...props.selection, ...item }, true).then(res => {
				props.setTargets(res.data.data);
				props.setTargetLoading(false);
			})
		}
	}

	const handleResize = (e, direction, ref, d) => {
		setUpdatingWidth(sidebarWidth + d.width);
	}

	const handleResizeStop = (e, direction, ref, d) => {
		setSidebarWidth(sidebarWidth + d.width);
		localStorage.setItem('DrawerWidth', sidebarWidth + d.width);
		setUpdatingWidth(0);
	}

	const toggleSidebar = (forced) => {
		if (forced !== undefined) setSideBarOpen(forced);
		else setSideBarOpen(!sideBarOpen);
	}

	const openEditDomain = (domain) => {
		setSelectedDomain(domain);
		setEditDomainOpen(true);
	}

	const openEditProgram = (program) => {
		setSelectedProgram(program);
		setEditProgramOpen(true);
	}

	const handleUpdateDomain = (domain) => {
		setEditDomainOpen(false);
		updateDomain(domain.id, { name: domain.name }).then(() => {
			props.setSnackbar('success', 'Domain name updated!');
			fetchFullData(props.patient.id, false);
		});
	}

	const handleUpdateProgram = (program) => {
		setEditProgramOpen(false);
		props.setTargetLoading(true);
		ProgramService.updateProgram(program.id, program.program).then(() => {
			props.setTargetLoading(false);
			props.setSnackbar('success', 'Program updated!');
			fetchFullData(props.patient.id, false);
		});
	}

	const handleDeleteProgram = (program) => {
		ProgramService.deleteProgram(program.id).then(() => {
			props.setSnackbar('success', 'Program deleted!');
			const selection = { ...props.selection, program: 'all' };
			fetchFullData(props.patient.id, false, selection);
		})
	}

	const handleDeleteDomain = (domain) => {
		deleteDomain(domain.id).then(() => {
			props.setSnackbar('success', 'Domain deleted!');
			fetchFullData(props.patient.id, true);
		})
	}

	const handleArchiveProgram = (program, unarchive = false) => {
		ProgramService.archiveProgram(program.id, unarchive).then(() => {
			props.setSnackbar('success', `Program ${unarchive ? 'unarchived' : 'archived'}!`);
			if (!showArchived) {
				const selection = { ...props.selection, program: 'all' };
				fetchFullData(props.patient.id, false, selection);
			} else {
				fetchFullData(props.patient.id, false);
			}
		})
	}

	const handleArchiveDomain = (domain, unarchive = false) => {
		archiveDomain(domain.id, unarchive).then(() => {
			props.setSnackbar('success', `Domain ${unarchive ? 'unarchived' : 'archived'}!`);
			fetchFullData(props.patient.id, !showArchived);
		})
	}

	const handleIdle = () => {
		props.logout(true);
	}

	const adminPage = useRouteMatch({
		path: '/admin',
		exact: false,
	});

	const userSettingPage = useRouteMatch({
		path: '/user-setting',
		exact: false,
	});

	const libraryPage = useRouteMatch({
		path: '/user-setting/my-library',
		exact: true,
	});

	const dataPage = useRouteMatch({
		path: '/data',
		exact: true,
	});

	return (
		<div>
			{props.isMobile ? 
				<MobileSidebar 
					open={sideBarOpen} 
					toggleSidebar={() => toggleSidebar(false)}
				/>
			: sidebarWidth &&
				<Sidebar
					editProgram={openEditProgram}
					deleteProgram={handleDeleteProgram}
					editDomain={openEditDomain}
					deleteDomain={handleDeleteDomain}
					width={sidebarWidth}
					filterByItem={(item) => filterByItem(item)}
					handleResize={handleResize}
					handleResizeStop={handleResizeStop}
					open={!adminPage && !libraryPage}
					setShowArchived={updateShowArchived}
					archiveDomain={handleArchiveDomain}
					archiveProgram={handleArchiveProgram}
				/>
			}

			{(!props.isMobile && sidebarWidth && libraryPage) &&
				<LibrarySidebar
					width={sidebarWidth}
					handleResize={handleResize}
					handleResizeStop={handleResizeStop}
					editDomain={openEditDomain}
					open={!!libraryPage}
				/>
			}

			{/* admin page */}
			{!!adminPage ? props.isMobile ? <Header toggleSidebar={() => toggleSidebar(true)}/> : <AdminHeader /> : null} 
			{!!adminPage &&
				<div className={`${classes.content} ${classes.adminContent}`}>
					{props.user.id && props.children}
				</div>
			}

			{/* clinical page */}
			{!adminPage && 
				<div style={{ marginLeft: props.isMobile ? 0 : updatingWidth ? updatingWidth : (sidebarWidth || defaultDrawerWidth) }}>
					{(props.isMobile || !userSettingPage) && <Header toggleSidebar={() => toggleSidebar(true)}/>}
					{!props.isMobile && !!userSettingPage && <UserSettingHeader />}
					<div className={classes.content}>
						{props.user.id && props.children}
					</div>
				</div>
			}
			{!adminPage && !dataPage && <Session onSessionEnd={() => {}} />}

			<SnackbarComponent />
			<GlobalDialog />
			<UploadDialog />
			<ZendeskDialog />
			<UpdateSubscription />
			<EditDomain
				onClose={() => setEditDomainOpen(false)}
				open={editDomainOpen}
				updateDomain={handleUpdateDomain}
				domain={selectedDomain}
			/>
			<NewProgram
				onClose={() => setEditProgramOpen(false)}
				open={editProgramOpen}
				updateProgram={handleUpdateProgram}
				program={selectedProgram}
			/>
			{/* <IdleTimer
				onIdle={handleIdle}
				timeout={30 * 24 * 60 * 60 * 1000} // 30 days in milliseconds
			/> */}
		</div>
	)
}

BaseLayout.propTypes = {
	children: PropTypes.node,
	// redux state
	domains: PropTypes.array.isRequired,
	filteredDomains: PropTypes.array.isRequired,
	selection: PropTypes.object.isRequired,
	patient: PropTypes.object.isRequired,
	patients: PropTypes.array.isRequired,
	user: PropTypes.object.isRequired,
	token: PropTypes.string.isRequired,
	// reducers
	getPatients: PropTypes.func.isRequired,
	setTargets: PropTypes.func.isRequired,
	setSelection: PropTypes.func.isRequired,
	setSnackbar: PropTypes.func.isRequired,
	setPatient: PropTypes.func.isRequired,
	setTargetLoading: PropTypes.func.isRequired,
	setAll: PropTypes.func.isRequired,
	tokenLogin: PropTypes.func.isRequired,
	logout: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
	setMemos: PropTypes.func.isRequired,
	setSidebarWidthRedux: PropTypes.func.isRequired,
	isMobile: PropTypes.bool.isRequired,
	setIsMobile: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	domains: state.data.domains,
	filteredDomains: state.data.filteredDomains,
	selection: state.general.selection,
	isMobile: state.general.isMobile,
	patient: state.patient.patient,
	patients: state.patient.patients,
	user: state.auth.user,
	token: state.auth.token,
})

export default connect(mapStateToProps, {
	setTargets, setSelection, tokenLogin,
	setSnackbar, setPatient, setTargetLoading, setAll, getPatients,
	logout, setLoading, setMemos, setSidebarWidthRedux, setIsMobile
})(BaseLayout);
