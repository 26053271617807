import { getDataSheet } from "./DataSheetService";
import { getSessionTargets } from "./TargetService";
import { sessionDataSyncRetryInterval } from "../constants";
import { submitSessionData } from "./sessionService";
import { detectTargetChanges } from "../utils/targetDataChangeDetector";
import store from '../store/store';

export const fetchNewTargetData = (session, resolve = null) => {
  return new Promise((r) => {
    if (store.getState().sessions.activeSessionId !== session?.id) {
      resolve ? resolve(session.targetData): r(session.targetData);
    } else {
      fetchTargetData(session.patientId, session.date, session.dataSheetId)
      .then((targetData) => resolve ? resolve(targetData) : r(targetData))
      .catch((e) => {
        if (e?.response?.status !== 404) {
          setTimeout(() => fetchNewTargetData(session, resolve ? resolve : r), sessionDataSyncRetryInterval);
        }
      });
    }
  });
};

export const sendData = (session, resolve = null, retry = true) => {
  return new Promise((r) => {
    if (retry === true && store.getState().sessions.activeSessionId !== session.id) {
      resolve ? resolve(session.targetIdDataIdMap): r(session.targetIdDataIdMap);
    } else {
      submitSessionData(session)
      .then((result) => resolve ? resolve(result) : r(result))
      .catch((e) => {
        if (e?.response?.status !== 404) {
          setTimeout(() => sendData(session, resolve ? resolve : r), sessionDataSyncRetryInterval);
        }
      });
    }
  });
};

export const fetchTargetData = async (patientId, date, dataSheet) => {
  let targetData = null;
  if (dataSheet && dataSheet !== -1) {
    const response = await Promise.all([
      getDataSheet(dataSheet),
      getSessionTargets(patientId, date),
    ]);
    const sheetTargets = response[0].data.data.targets;
    sheetTargets.sort((target1, target2) => {
      return target1.order > target2.order ? 1 : -1;
    });
    const sheetTargetIds = sheetTargets.map((e) => e.id);
    const allTargets = response[1].data.data;
    targetData = allTargets.filter((e) => sheetTargetIds.includes(e.id));
    targetData.sort((target1, target2) => {
      return sheetTargetIds.indexOf(target1.id) >
        sheetTargetIds.indexOf(target2.id)
        ? 1
        : -1;
    });
  } else {
    const response = await getSessionTargets(patientId, date);
    targetData = response.data.data;
    targetData.sort((target1, target2) => {
      return target1.order > target2.order ? 1 : -1;
    });
  }
  return targetData;
};

const removeUnavailableTargetData = (sessionData, newTargetData) => {
    const newTargetIds = newTargetData.map(e => e.id);
    return sessionData.filter(e => newTargetIds.includes(e.targetId));
}

const removeTargetDataIfDataTypeChanged = (sessionData, newTargetData) => {
    const targetDataTypeMap = {};
    newTargetData.forEach((target) => {
        targetDataTypeMap[target.id] = target.dataType;
    });
    return sessionData.filter(e => targetDataTypeMap[e.targetId] === e.dataType);
}

const getTargetAsJson = (target) => {
  const { order, pinnedAt, isStarred, isAutoZero, targetDataCount, createdAt, updatedAt, program, maintenanceSetting, ...rest } = target;
  return JSON.stringify({ ...rest, program: program.id });
}

export const getTargetChanges = (session, newTargetData) => {
  const currentTargetIds = newTargetData.map(e => e.id);
  const oldTargetIds = session.targets.map(e => e.id);
  const commonTargetIds = currentTargetIds.filter(e => oldTargetIds.includes(e));
  const deletedTargets = session.targets.filter(e => !currentTargetIds.includes(e.id)).map(e => ({name: e.name, changes: ["Removed target"] }));
  const newTargets = newTargetData.filter(e => !oldTargetIds.includes(e.id)).map(e => ({name: e.name, changes: ["Added target"] }));
  const changedTargets = session.targets.filter(e => 
    commonTargetIds.includes(e.id) 
    && getTargetAsJson(newTargetData.find(e1 => e1.id === e.id)) !== getTargetAsJson(e)
  ).map(e => ({ name: e.name, changes: detectTargetChanges(e, newTargetData.find(e1 => e1.id === e.id)) }))
  .filter(e => !!e.changes?.length);
  return (deletedTargets?.length || newTargets?.length || changedTargets?.length) 
    ? changedTargets.concat(newTargets, deletedTargets) : null;
}

export const updateSessionBasedOnNewTargetData = (session, newTargetData) => {
  session.targets = newTargetData;
  session.data = removeUnavailableTargetData(session.data.slice(), newTargetData);
  session.data = removeTargetDataIfDataTypeChanged(session.data, newTargetData);
  return session;
};