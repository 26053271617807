import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import {
    Button, Grid,
    Menu, MenuItem, Popover, InputAdornment, TextField,
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import { DatePicker } from "@material-ui/pickers";
import debounce from 'lodash.debounce';

//Services
import * as ProgramService from '../../services/ProgramService';
import * as DomainService from '../../services/DomainService';
import * as TargetService from '../../services/TargetService';
import * as LibraryService from '../../services/LibraryService';

//Components
import NewProgram from './NewProgram';
import NewTarget from './NewTarget';
import NewDomain from './NewDomain';
import { statuses, phases, access, stars } from '../../constants';
import { setTargets, setAll } from '../../store/data/actions';
import { setLoading, setSelection, setSnackbar, setTargetLoading, setGlobalDialog, setUploadDialog } from '../../store/general/actions';
import { uploadTarget } from '../../services/TargetService';
import CanUser, { CanUserFunc } from '../CanUser';
import LoadingCheck from '../../assets/progress/LoadingCheck';
import TargetGrid from './TargetGrid';
import GroupedDropdownButton from '../../assets/button/GroupedDropdownButton';
import { downloadClinicalData } from '../../services/TargetService';
import TooltipedIconButton from '../../assets/button/TooltipedIconButton';
import MaintenanceModal from './MaintenanceModal';
import urlDownload from '../../utils/urlDownload';
import DuplicateTarget from './DuplicateTarget';
import LibraryImport from '../Library/LibraryImport';

//Constants
import { localStorageEnum } from '../../constants';
import ProgramSelect from '../ProgramSelect';
import MobileSearchInput from '../../assets/input/MobileSearchInput';

const useStyles = makeStyles(theme => ({
    newBtnWrapper: {
        padding: '5px 0px 5px 30px',
        backgroundColor: '#FFFFFF'
    },
    newBtnWrapperMobile: {
        padding: '5px 10px',
        backgroundColor: '#FFFFFF'
    },
    domainStatusWrapper: {
        backgroundColor: '#F4F4F4',
    },
    domainWrapper: {
        padding: '12px 0px 12px 30px',
    },
    currentDomain: {
        color: '#30313E',
        fontSize: 18,
        fontWeight: 'bold',
        display: 'inline-block',
        marginRight: 16,
    },
    menuItem: {
        textTransform: 'capitalize',
    },
    dateClear: {
        marginLeft: 10,
        marginBottom: 10,
    },
    downloadButton: {
        marginRight: 20,
    },
    uploadButton: {
        marginRight: 10,
    },
    searchField: {
        width: '100%'
    },
}));

const Program = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const {
        domains, targets, selection, patient, loading, isMobile,
        setAll, setTargets, setSelection, setSnackbar, setLoading, setTargetLoading,
        setGlobalDialog, programs, setUploadDialog
    } = props;

    const [dialogOpen, setDialogOpen] = useState({
        newProgram: false,
        newTarget: false,
        newDomain: false,
        editTarget: false,
        maintenance: false,
        duplicateTarget: false,
        importLibrary: false,
    });
    const [anchorEl, setAnchorEl] = useState({
        status: null,
        phase: null,
        action: null,
        openedAt: null,
        masteredAt: null,
    });
    const [selectedId, setSelectedId] = useState(null);
    const [selectedTarget, setSelectedTarget] = useState(null);

    const [date, setDate] = useState(null);
    const [newButtonOptions, setNewButtonOptions] = useState([]);
    const [selectedButtonOption, setSelectedButtonOption] = useState(0);
    const [selectedProgramId, setSelectedProgramId] = useState(0);
    const [gridTargets, setGridTargets] = useState([]);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState({ sortAs: '', sortBy: '' });

    useEffect(() => {
        const options = [];
        if (CanUserFunc(access.domains.add)) options.push({ name: 'New Domain', onClick: openDialog('newDomain') });
        if (CanUserFunc(access.programs.add)) options.push({ name: 'New Program', onClick: openDialog('newProgram') });
        if (CanUserFunc(access.targets.add)) options.push({ name: 'New Target', onClick: openDialog('newTarget') });
        if (CanUserFunc(access.targets.add)) options.push(
            { name: 'Import From Library', onClick: openDialog('importLibrary') }
        )
        setNewButtonOptions(options);
        refreshData();
    }, []);

    useEffect(() => {
        updateSelectedButtonOption();
    }, [newButtonOptions, selection]);

    useEffect(() => {
        if (selectedProgramId !== 0) {
            openDialog('newTarget')();
        }
    }, [selectedProgramId])

    useEffect(() => {
        setGridTargets(targets);
        searchTarget();
    }, [targets])

    useEffect(() => {
        debouncedSearchTarget();
    }, [search])

    useEffect(() => {
        let programFilters = localStorage.getItem(localStorageEnum.programFilters);
        if (programFilters) {
            programFilters = JSON.parse(programFilters);
            setSort(programFilters.sort);
        }
    }, []);

    useEffect(() => {
        persistSort(sort);
    }, [sort])

    const persistSort = (sort) => {
        const programFilters = { sort };
        localStorage.setItem(localStorageEnum.programFilters, JSON.stringify(programFilters));
    }

    const searchTarget = () => {
        const searchInput = search.toLowerCase();
        const filteredTargets = targets.filter((target) => {
            return target.program.name.toLowerCase().includes(searchInput) || target.name.toLowerCase().includes(searchInput)
        });
        setGridTargets(filteredTargets);
    }

    const debouncedSearchTarget = debounce(searchTarget, 500);

    const updateSelectedButtonOption = () => {
        if (newButtonOptions.length > 0) {
            const newDomain = newButtonOptions.find(e => e.name === 'New Domain');
            const newProgram = newButtonOptions.find(e => e.name === 'New Program');
            const newTarget = newButtonOptions.find(e => e.name === 'New Target');

            if (selection.all && newDomain) {
                setSelectedButtonOption(newButtonOptions.indexOf(newDomain));
            } else if (selection.program === 'all' && selection.domain && newProgram) {
                setSelectedButtonOption(newButtonOptions.indexOf(newProgram));
            } else if (selection.program !== 'all' && !selection.all && newTarget) {
                setSelectedButtonOption(newButtonOptions.indexOf(newTarget));
            }
        }
    }

    const refreshData = () => {
        setTargetLoading(true);
        if (patient?.id) {
            ProgramService.getFullDataByPatientId({ ...selection, patient: patient.id }, patient.id).then(res => {
                setAll(res);
                setTargetLoading(false);
            });
        } else {
            setTargetLoading(false);
        }
    }

    const openDialog = type => () => {
        setDialogOpen({ ...dialogOpen, [type]: true });
    }

    const closeDialog = type => () => {
        if (type === 'newTarget') {
            setSelectedProgramId(0);
            setSelectedTarget(null);
            setSelectedId(0);
        }
        setDialogOpen({ ...dialogOpen, [type]: false });
    }

    const openAnchorEl = (type, el) => {
        setAnchorEl({ ...anchorEl, [type]: el });
    }

    const closeAnchorEl = (type, clearTarget = false) => () => {
        setAnchorEl({ ...anchorEl, [type]: null });
        if (clearTarget) {
            setSelectedId(0);
            setSelectedTarget(null);
        }
    }

    const handleNewProgram = (data) => {
        closeDialog('newProgram')();
        setLoading('newProgram');
        ProgramService.postProgram(data.program).then(res => {
            setSelectedProgramId(data.newTarget ? res.data.data.programId : 0);
            setLoading('newProgram', false);
            setSnackbar('success', 'New program added!');
            refreshData();
        }).catch(error => {
            setLoading('newProgram', false);
        });
    }

    const handleDuplicateTarget = (duplicates) => {
        closeDialog('duplicateTarget')();
        setLoading('duplicateTarget');
        TargetService.duplicateTarget(selectedId, duplicates).then(res => {
            setLoading('duplicateTarget', false);
            setSnackbar('success', 'Target duplicated!');
            refreshData();
        }).catch(error => {
            setLoading('duplicateTarget', false);
        })
        setSelectedTarget(null);
        setSelectedId(0);
    }

    const handleNewTarget = (data) => {
        if (selectedTarget) {
            updateTarget(data);
            return;
        }
        setSelectedProgramId(0);
        closeDialog('newTarget')();
        setLoading('newTarget');
        TargetService.postTarget(data).then(res => {
            setLoading('newTarget', false);
            setSnackbar('success', 'New target added!');
            refreshData();
        }).catch(error => {
            setLoading('newTarget', false);
        });
        setSelectedTarget(null);
    }

    const handleNewDomain = (data) => {
        setLoading('newDomain');
        closeDialog('newDomain')();
        DomainService.createDomain(data).then(res => {
            setLoading('newDomain', false);
            refreshData();
            setSnackbar('success', 'Domain added!');
        })
    }

    const sortHandler = (sortBy, sortAs) => {
        setTargetLoading(true);
        persistSort({ sortBy, sortAs });
        TargetService.getFilteredTargets({ ...selection }, true).then(res => {
            setSelection({ ...selection });
            setTargets(res.data.data);
            setGridTargets(res.data.data);
            setTargetLoading(false);
            setSort({ sortBy, sortAs });
        })
    }

    const handleStatusChange = status => () => {
        setTargetLoading(true);
        TargetService.getFilteredTargets({ ...selection, status: status }, true).then(res => {
            setSelection({ ...selection, status });
            setTargets(res.data.data);
            setGridTargets(res.data.data);
            setTargetLoading(false);
        })
        closeAnchorEl('statusFilter')();
    }

    const handleStarChange = star => () => {
        setTargetLoading(true);
        TargetService.getFilteredTargets({ ...selection, star: star }, true).then(res => {
            setSelection({ ...selection, star });
            setTargets(res.data.data);
            setGridTargets(res.data.data);
            setTargetLoading(false);
        })
        closeAnchorEl('starFilter')();
    }

    const openMenu = (id, type) => event => {
        event.stopPropagation();
        setSelectedId(id);
        setSelectedTarget(targets.find(e => e.id === id));
        openAnchorEl(type, event.currentTarget);
    }

    const handleChangeTargetStatus = status => () => {
        setLoading('editStatus');
        const data = { status }
        if (status === 'active') {
            data.openedAt = selectedTarget.openedAt || new Date().toISOString();
        }
        if (!phases.includes(selectedTarget.phase)) {
            data.phase = phases[0];
        }
        if (selectedTarget.masteredAt && status !== 'mastered') {
            data.masteredAt = null;
        }
        TargetService.updateTarget(selectedId, data).then(res => {
            setLoading('editStatus', false);
            refreshData();
            setSnackbar('success', 'Status updated!');
        })
        closeAnchorEl('status')();
        setSelectedTarget(null);
        setSelectedId(0);
    }

    const handleChangeTargetPhase = phase => () => {
        if (phase === 'maintenance') {
            openDialog('maintenance')();
            closeAnchorEl('phase')();
        } else {
            setLoading('editPhase');
            const data = { phase }
            TargetService.updateTarget(selectedId, data).then(res => {
                setLoading('editPhase', false);
                refreshData();
                setSnackbar('success', 'Phase updated!');
            })
            closeAnchorEl('phase')();
            setSelectedTarget(null);
            setSelectedId(0);
        }
    }

    const handleTargetAction = action => () => {
        if (action === 'delete') {
            setGlobalDialog(
                'Delete target?',
                'Deleted target cannot be restored, please proceed with caution.',
                (answer) => handleDeleteAlert(answer)
            )
        }
        if (action === 'archive') {
            setLoading('archive');
            const data = { status: 'archived' }
            TargetService.updateTarget(selectedId, data).then(res => {
                setLoading('archive', false);
                refreshData();
                setSnackbar('success', 'Target archived!');
            })
            setSelectedTarget(null);
            setSelectedId(0);
        }
        if (action === 'unarchive') {
            setLoading('unarchive');
            TargetService.unarchiveTarget(selectedId).then(res => {
                setLoading('unarchive', false);
                refreshData();
                setSnackbar('success', 'Target unarchived!');
            })
            setSelectedTarget(null);
            setSelectedId(0);
        }
        if (action === 'duplicate') {
            openDialog('duplicateTarget')();
        }
        if (action === 'edit') {
            setSelectedTarget({ ...selectedTarget, program: selectedTarget.program.id });
            openDialog('newTarget')();
        }
        if (action === 'saveToLibrary') {
            LibraryService.addTargetToLibrary(selectedId).then((res) => {
                if (res.data.success) {
                    setSnackbar('success', 'Target is saved to library!');
                }
            })
        }
        if (action === 'graph') {
            handleGraph(selectedTarget.program.domain.id, selectedTarget.program.id, selectedTarget.id)()
        }
        closeAnchorEl('action')();
    }

    const handleRowEdit = (target) => () => {
        setSelectedTarget({ ...target, program: target.program.id });
        openDialog('newTarget')();
    }

    const handleDeleteAlert = answer => {
        if (answer) {
            setLoading('deleteTarget');
            TargetService.deleteTarget(selectedId).then(res => {
                setLoading('deleteTarget', false);
                refreshData();
                setSelection({ ...selection }, null);
                setSnackbar('success', 'Target deleted!');
            }).catch(err => {
                if (err.response && err.response.status === 400) {
                    setSnackbar('warning', 'Data exist. Target cannot be deleted!');
                    setLoading('deleteTarget', false);
                } else {
                    setSnackbar('error', 'An error occured!');
                }
            })
        }
        setSelectedTarget(null);
        setSelectedId(0);
    }

    const openCalendar = (id, type, date) => event => {
        event.stopPropagation();
        setSelectedId(id);
        setDate(date);
        openAnchorEl(type, event.currentTarget);
    }

    const handleChangeOpened = openedAt => {
        setLoading('opened');
        const data = { openedAt }
        TargetService.updateTarget(selectedId, data).then(res => {
            setLoading('editOpened', false);
            refreshData();
            setSnackbar('success', 'Date opened updated!');
        })
        closeAnchorEl('openedAt')();
    }

    const handleChangeMastered = masteredAt => {
        setLoading('editMastered');
        const data = { masteredAt }
        TargetService.updateTarget(selectedId, data).then(res => {
            setLoading('editMastered', false);
            refreshData();
            setSnackbar('success', 'Date mastered updated!');
        })
        closeAnchorEl('masteredAt')();
        setSelectedTarget(null);
        setSelectedId(0);
    }

    const handleGraph = (domainId, programId, targetId) => () => {
        setSelection({ ...selection, all: false, domain: domainId, program: programId }, targetId);
        history.push('/analysis')
    }

    const handleStar = (id, isStarred) => (event) => {
        event.stopPropagation();
        setSelectedId(id);
        const updateTargets = [...targets];
        const index = updateTargets.findIndex(target => target.id === id);
        updateTargets[index].isStarred = isStarred;
        setTargets(updateTargets);
        setGridTargets(updateTargets);
        TargetService.updateTarget(id, { isStarred })
    }

    const updateTarget = updatedTarget => {
        closeDialog('newTarget')();
        setTargetLoading(true);
        const data = { ...updatedTarget }
        TargetService.updateTarget(selectedId, data).then(res => {
            setTargetLoading(false);
            refreshData();
            setSnackbar('success', 'Target updated!');
        })
        setSelectedTarget(null);
        setSelectedId(0);
    }

    const handleDownload = () => {
        const filter = {
            ...selection,
            patient: patient.id,
        };

        setLoading('downloadClinical');
        downloadClinicalData(search, filter).then((res) => {
            urlDownload(res.data.data.path);
            setLoading('downloadClinical', false);
        })
    }

    const handleUpload = () => {
        setUploadDialog(
            'Upload Domain, Program, and Targets',
            'Import domain, program and target data using the provided template.',
            require("../../resources/templates/Target_Import_Template.xlsx"),
            'Target_Import_Template.xlsx',
            ['.xlsx'],
            submitUploadTarget,
            refreshData,
        );
    }

    const submitUploadTarget = async (file) => {
        file.append('patientId', patient?.id);
        file.append('branchId', patient?.branch?.id);
        return await uploadTarget(file);
    }

    const handleSubmitMaintenance = (maintenanceSetting) => {
        setLoading('editPhase');
        closeDialog('maintenance')();
        const data = { maintenanceSetting, phase: 'maintenance' }
        TargetService.updateTarget(selectedId, data).then(res => {
            setLoading('editPhase', false);
            refreshData();
            setSnackbar('success', 'Phase updated!');
        })
        setSelectedTarget(null);
        setSelectedId(0);
    }

    const handleSearch = (event) => {
        setSearch(event.target.value);
    }

    return (
        <div style={{ maxWidth: "100%", overflow: 'hidden' }}>
            <Grid container>
                <Grid item xs={12} className={isMobile ? classes.newBtnWrapperMobile : classes.newBtnWrapper} container alignItems="center">
                    <Grid item xs={isMobile ? 10 : 3} container alignItems="center">
                        <GroupedDropdownButton
                            id="program-page-add"
                            buttons={newButtonOptions}
                            overrideSelectedButton={selectedButtonOption}
                            disabled={!patient?.id}
                        />
                        <div style={{ width: 26 }}>
                            <LoadingCheck show={loading.newDomain || loading.newProgram || loading.newTarget} size={24} />
                        </div>
                    </Grid>
                    <Grid item xs={isMobile ? 2 : 6} id="program-page-search" relative container justify='flex-end'>
                        {isMobile ? <MobileSearchInput onSearch={handleSearch} /> : <TextField
                            id="program-page-search-field"
                            variant="outlined"
                            className={classes.searchField}
                            placeholder="Program Name, Target Name..."
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />}
                    </Grid>
                    {!isMobile && <Grid item xs={3} container justify="flex-end">
                        <CanUser
                            perform={access.targets.uploadDownload}
                            yes={() => (
                                <React.Fragment>
                                    <TooltipedIconButton
                                        icon={<PublishIcon />}
                                        onClick={handleUpload}
                                        tooltip="Upload"
                                        className={classes.uploadButton}
                                        id="program-page-upload"
                                    />
                                    <TooltipedIconButton
                                        icon={<GetAppIcon />}
                                        onClick={handleDownload}
                                        disabled={!targets?.length || loading.downloadClinical}
                                        tooltip="Download"
                                        className={classes.downloadButton}
                                        id="program-page-download"
                                    />
                                </React.Fragment>
                            )}
                        />
                    </Grid>}
                </Grid>
                <DuplicateTarget open={dialogOpen.duplicateTarget} onClose={closeDialog('duplicateTarget')} duplicateTarget={(duplicates) => handleDuplicateTarget(duplicates)} />
                <NewDomain open={dialogOpen.newDomain} onClose={closeDialog('newDomain')} addDomain={(domain) => handleNewDomain(domain)} />
                <NewProgram open={dialogOpen.newProgram} onClose={closeDialog('newProgram')} addProgram={(program) => handleNewProgram(program)} />
                <NewTarget
                    open={dialogOpen.newTarget}
                    onClose={closeDialog('newTarget')}
                    program={selectedProgramId}
                    addTarget={(target) => handleNewTarget(target)}
                    target={selectedTarget}
                    grayedOutFields={selectedTarget?.id ? [] : ['status', 'masteredAt', 'openedAt']}
                />
                <LibraryImport
                    open={dialogOpen.importLibrary}
                    onClose={closeDialog('importLibrary')}
                    fromClinical
                />
                <MaintenanceModal
                    open={dialogOpen.maintenance}
                    onClose={closeDialog('maintenance')}
                    onSubmit={handleSubmitMaintenance}
                />
            </Grid>
            <Grid container className={classes.domainStatusWrapper}>
                {isMobile ? <Grid item xs={12}>
                    <ProgramSelect />
                </Grid> : <Grid item xs={12} className={classes.domainWrapper}>
                    <span className={classes.currentDomain}>
                        {selection.all ? 'All Domain' : domains.find(e => e.id === selection.domain)?.name || 'Unknown'}
                        {selection.program !== 'all'
                            ? ` > ${programs.find(e => e.id === selection.program)?.name || 'Unknown'}`
                            : ''
                        }
                    </span>
                </Grid>}
            </Grid>
            <TargetGrid
                selectedId={selectedId}
                classes={classes}
                openMenu={openMenu}
                openCalendar={openCalendar}
                handleRowEdit={handleRowEdit}
                handleStar={handleStar}
                targets={gridTargets}
                onSort={sortHandler}
                sort={sort}
                isStatusFilterActive={selection.status !== 'all'}
                isStarFilterActive={selection.star !== 'all'}
            />

            <Menu
                id="star-filter"
                anchorEl={anchorEl.starFilter}
                keepMounted
                open={Boolean(anchorEl.starFilter)}
                onClose={closeAnchorEl('starFilter', true)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                getContentAnchorEl={null}
            >
                {stars.map(e => (
                    <MenuItem key={e} id={`program-table-star-filter-${e}`} selected={selection.star === e} className={classes.menuItem} onClick={handleStarChange(e)}>{e}</MenuItem>
                ))}
            </Menu>

            <Menu
                id="status-filter"
                anchorEl={anchorEl.statusFilter}
                keepMounted
                open={Boolean(anchorEl.statusFilter)}
                onClose={closeAnchorEl('statusFilter', true)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                getContentAnchorEl={null}
            >
                {statuses.map(e => (
                    <MenuItem key={e} id={`program-table-status-filter-${e}`} selected={selection.status === e} className={classes.menuItem} onClick={handleStatusChange(e)}>{e}</MenuItem>
                ))}
            </Menu>

            <Menu
                id="status-menu"
                anchorEl={anchorEl.status}
                keepMounted
                open={Boolean(anchorEl.status)}
                onClose={closeAnchorEl('status', true)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                getContentAnchorEl={null}
            >
                {statuses.filter(e => e !== 'all' && e !== 'archived').map(e => (
                    <MenuItem key={e} id={`program-table-status-menu-${e}`} className={classes.menuItem} onClick={handleChangeTargetStatus(e)}>{e}</MenuItem>
                ))}
            </Menu>

            <Menu
                id="phase-menu"
                anchorEl={anchorEl.phase}
                keepMounted
                open={Boolean(anchorEl.phase)}
                onClose={closeAnchorEl('phase', true)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                getContentAnchorEl={null}
            >
                {phases.map(e => (
                    <MenuItem key={e} id={`program-table-phase-menu-${e}`} className={classes.menuItem} onClick={handleChangeTargetPhase(e)}>{e}</MenuItem>
                ))}
            </Menu>

            <Menu
                id="action-menu"
                anchorEl={anchorEl.action}
                keepMounted
                open={Boolean(anchorEl.action)}
                onClose={closeAnchorEl('action', true)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                getContentAnchorEl={null}
            >
                {CanUserFunc(access.targets.edit) ?
                    <MenuItem id="program-table-row-action-edit" className={classes.menuItem} onClick={handleTargetAction('edit')}>edit</MenuItem>
                    : null
                }
                {CanUserFunc(access.analysis.view) ?
                    <MenuItem id="program-table-row-action-graph" className={classes.menuItem} onClick={handleTargetAction('graph')} disabled={selectedTarget?.pendingReview}>Graph</MenuItem>
                    : null
                }
                {CanUserFunc(access.targets.add) ?
                    <MenuItem id="program-table-row-action-duplicate" className={classes.menuItem} onClick={handleTargetAction('duplicate')} disabled={selectedTarget?.pendingReview}>duplicate</MenuItem>
                    : null
                }

                {CanUserFunc(access.targets.edit) ?
                    selectedTarget?.status === 'archived' ?
                        <MenuItem id="program-table-row-action-unarchive" className={classes.menuItem} onClick={handleTargetAction('unarchive')} disabled={selectedTarget?.pendingReview}>unarchive</MenuItem>
                        :
                        <MenuItem id="program-table-row-action-archive" className={classes.menuItem} onClick={handleTargetAction('archive')} disabled={selectedTarget?.pendingReview}>archive</MenuItem>
                    : null
                }
                {CanUserFunc(access.targets.remove) ?
                    <MenuItem id="program-table-row-action-delete" className={classes.menuItem} onClick={handleTargetAction('delete')}>delete</MenuItem>
                    : null
                }
                <MenuItem id="program-table-row-action-save-to-library" onClick={handleTargetAction('saveToLibrary')} disabled={selectedTarget?.pendingReview}>Save to library</MenuItem>
            </Menu>

            <Popover
                id="openedAt-pop"
                open={Boolean(anchorEl.openedAt)}
                anchorEl={anchorEl.openedAt}
                onClose={closeAnchorEl('openedAt', true)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <DatePicker
                    autoOk
                    variant="static"
                    openTo="date"
                    value={date}
                    onChange={handleChangeOpened}
                />
                <Button className={classes.dateClear} onClick={() => handleChangeOpened(null)}>Clear</Button>
            </Popover>

            <Popover
                id="masteredAt-pop"
                open={Boolean(anchorEl.masteredAt)}
                anchorEl={anchorEl.masteredAt}
                onClose={closeAnchorEl('masteredAt', true)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <DatePicker
                    autoOk
                    variant="static"
                    openTo="date"
                    value={date}
                    onChange={handleChangeMastered}
                />
                <Button className={classes.dateClear} onClick={() => handleChangeMastered(null)}>Clear</Button>
            </Popover>
            {/* Onboarding Disabled */}
            {/* <CustomOnboarding steps={programPageSteps} /> */}
        </div>
    );
}

Program.propTypes = {
    domains: PropTypes.array.isRequired,
    programs: PropTypes.array.isRequired,
    targets: PropTypes.array.isRequired,
    selection: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    setAll: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    setTargets: PropTypes.func.isRequired,
    setSelection: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    setTargetLoading: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
    setUploadDialog: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    domains: state.data.domains,
    programs: state.data.programs,
    targets: state.data.targets,
    selection: state.general.selection,
    patient: state.patient.patient,
    loading: state.general.loading,
    user: state.auth.user,
    isMobile: state.general.isMobile
})

export default connect(
    mapStateToProps,
    {
        setTargets, setSelection, setSnackbar, setLoading, setTargetLoading,
        setAll, setGlobalDialog, setUploadDialog
    }
)(Program)
