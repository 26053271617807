import agent from './agent';
import { localStorageEnum } from '../constants';

export const getFullDataByPatientId = (request, patientId, cancelTokens) => {
    return new Promise((resolve, reject) => {
        agent.get('/domain/patient/', {
            params: {
                id: patientId,
                orderBy: 'order',
                status: request.showArchived ? 'all' : undefined,
            },
            cancelToken: cancelTokens?.[0]
        }).then(response => {
            let programFilters = localStorage.getItem(localStorageEnum.programFilters);
            let params = {};
            if (programFilters) {
                programFilters = JSON.parse(programFilters);
                if (programFilters.sort?.sortBy && programFilters.sort?.sortAs) {
                    params = programFilters.sort;
                }
            }
            agent.post(
                '/target/filter', 
                { ...request, showArchived: undefined }, 
                { ...params, cancelToken: cancelTokens?.[1] })
            .then(targs => {
                const domains = response.data.data;
                let programs = [];
                const targets = targs.data.data;
                domains.forEach(domain => {
                    programs = [...programs, ...domain.programs]
                })
                resolve({ domains, programs, targets })
            })
        });
    });
}

export const getDomainsProgramsByPatientId = (patientId, includeArchived = false) => {
    return new Promise((resolve, reject) => {
        agent.get('/domain/patient/', {
            params: {
                id: patientId,
                orderBy: 'order',
                status: includeArchived ? 'all' : undefined,
            }
        }).then(response => {
            const domains = response.data.data;
            let programs = [];
            domains.forEach(domain => {
                programs = [...programs, ...domain.programs]
            })
            resolve({ domains, programs })
        })
    });
}

export const postProgram = (newProgram) => {
    return agent.post('/program/create', newProgram);
}

export const postTarget = (newTarget) => {
    return agent.post('/target/create', newTarget);
}

export const updateProgram = (programId, data) => {
    return agent.put('/program/update', { id: programId, ...data });
}

export const deleteProgram = (programId) => {
    return agent.delete('/program/delete', {
        params: {
            id: programId,
        }
    })
}

export const reorderProgram = (id, newPos) => {
    return agent.post('/program/reorder', { id, newPos });
}

export const archiveProgram = (programId, unarchive = false) => {
    if (unarchive) {
        return agent.put('/program/un-archive', { id: programId });
    } else {
        return agent.put('/program/update', { id: programId, status: 'archived' });
    }
}