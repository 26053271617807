import {
    SET_USER, SET_TOKEN, SET_UEK, SET_LOGIN_ERROR, LOGOUT, SUBSCRIPTION_DIALOG
} from './action-types';

const localToken = localStorage.getItem('token');

const initialState = {
    user: {},
    isLoggedIn: localToken !== 'undefined' && !!localToken ? true : false,
    token: localToken !== 'undefined' && !!localToken ? localToken : '',
    uek: '',
    loginError: '',
    showUpdateSubsctiption: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload,
                isLoggedIn: true,
                loginError: '',
            }
        
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload,
            }

        case SET_UEK:
            return {
                ...state,
                uek: action.payload,
            }

        case SET_LOGIN_ERROR:
            return {
                ...state,
                loginError: action.payload,
            }

        case SUBSCRIPTION_DIALOG:
            return {
                ...state,
                showUpdateSubsctiption: action.payload,
            }

        case LOGOUT:
            return {
                ...state,
                user: {},
                isLoggedIn: false,
                token: '',
                loginError: action.payload.isIdle ? 'Session expired. Please login again.' : action.payload.error || '',
            }

        default:
            return state
    }
}