export const statuses = ['all', 'active', 'inactive', 'mastered', 'archived'];
export const phases = ['baseline', 'intervention', 'generalization', 'maintenance'];
export const graphLineTypes = ['dashed', 'solid', 'no line'];
export const dataTypes = ['correctIncorrect', 'frequency', 'prompt', 'duration', 'interval', 'text', 'probe', 'rating', 'rate', 'imageVideo', 'quantity', 'category'];
export const accountTypes = [
    { name: 'all', label: 'all' },
    { name: 'employee', label: 'user' },
    { name: 'client', label: 'client' }
];
export const settingTypes = ['reminder', 'datasheet'];
export const maintenanceSettingTypes = ['day', 'session'];
export const stars = ['all', 'starred', 'unstarred'];

export const settingTypesEnum = {
    reminder: 'Reminders',
    datasheet: 'Data Sheet',
};

export const maintenanceSettingTypeEnum = {
    day: 'Day(s)',
    session: 'Session(s)',
};

export const dataTypeDict = {
    correctIncorrect: '+/-',
    frequency: 'Frequency',
    prompt: 'Prompt',
    duration: 'Duration',
    interval: 'Interval',
    text: 'Text',
    probe: 'TA/Probe',
    rating: 'Rating Scale',
    rate: 'Rate',
    imageVideo: 'Image/Video',
    quantity: 'Quantity',
    category: 'Category',
    masteredTargets: 'Mastered Targets',
};

export const newDataTypes = { ...dataTypeDict, rating: 'Rating' };

export const permissionGroupsEnum = {
    users: 'users',
    roles: 'roles',
    clients: 'clients',
    targets: 'targets',
    programs: 'programs',
    analysis: 'analysis',
    sites: 'sites',
    domains: 'domains',
    data: 'data',
    notes: 'notes',
    companies: 'companies',
    devices: 'devices',
};

export const permissionActionsEnum = {
    add: 'add',
    edit: 'edit',
    remove: 'remove',
    view: 'view',
    uploadDownload: 'uploadDownload',
};

const accessEnum = {
    users: { ...permissionActionsEnum },
    roles: { ...permissionActionsEnum },
    clients: { ...permissionActionsEnum },
    targets: { ...permissionActionsEnum },
    programs: { ...permissionActionsEnum },
    analysis: { ...permissionActionsEnum },
    sites: { ...permissionActionsEnum },
    domains: { ...permissionActionsEnum },
    data: { ...permissionActionsEnum },
    notes: { ...permissionActionsEnum },
    companies: { ...permissionActionsEnum },
    devices: { ...permissionActionsEnum },
};

Object.keys(permissionGroupsEnum).forEach((group) => {
    Object.keys(permissionActionsEnum).forEach((action) => {
        accessEnum[group][action] = { group, action };
    });
});

export const access = accessEnum;

const action = permissionActionsEnum;

export const permissionGroups = [
    { name: permissionGroupsEnum.users, label: 'Users', actions: [action.add, action.edit, action.remove, action.view, action.uploadDownload] },
    { name: permissionGroupsEnum.roles, label: 'Roles', actions: [action.add, action.edit, action.remove, action.view] },
    { name: permissionGroupsEnum.devices, label: 'Devices', actions: [action.view, action.remove] },
    { name: permissionGroupsEnum.clients, label: 'Clients', actions: [action.add, action.edit, action.remove, action.view, action.uploadDownload] },
    { name: permissionGroupsEnum.sites, label: 'Sites', actions: [action.add, action.edit, action.remove, action.view] },
    { name: permissionGroupsEnum.domains, label: 'Domains', actions: [action.add, action.edit, action.remove] },
    { name: permissionGroupsEnum.programs, label: 'Programs', actions: [action.add, action.edit, action.remove] },
    { name: permissionGroupsEnum.targets, label: 'Targets', actions: [action.add, action.edit, action.remove, action.view, action.uploadDownload] },
    { name: permissionGroupsEnum.data, label: 'Data', actions: [action.add, action.edit, action.remove, action.view, action.uploadDownload] },
    { name: permissionGroupsEnum.analysis, label: 'Analysis', actions: [action.view, action.uploadDownload] },
    { name: permissionGroupsEnum.notes, label: 'Notes', actions: [action.view, action.add, action.edit, action.remove] },
];

export const permissionActions = [
    { name: permissionActionsEnum.add, label: 'Add' },
    { name: permissionActionsEnum.edit, label: 'Edit' },
    { name: permissionActionsEnum.remove, label: 'Remove' },
    { name: permissionActionsEnum.view, label: 'View' },
    { name: permissionActionsEnum.uploadDownload, label: 'Upload/Download' },
];

export const additionalPermissions = [
    { name: 'hasWebAccess', desc: 'Allow this role to use web application.' },
    { name: 'hasMobileAccess', desc: 'Allow this role to use mobile application.' },
    { name: 'viewOnlyAssignedPatients', desc: 'This role has access to ALL clients in their assigned site(s).', isReverse: true },
    // we updated the checkbox label below to the opposite of it, hence on the form it will be opposite of its value
    // { name: 'viewOnlyAssignedPatients', desc: 'This role can only view their assigned patients.' },
];

/**
 * Clear all of this on logout
 */
export const localStorageEnum = {
    clientFilters: 'clientFilters',
    userFilters: 'userFilters',
    siteFilters: 'siteFilters',
    programFilters: 'programFilters',
    organizationFilters: 'organizationFilters',
    roleFilters: 'roleFilters',
    deviceFilters: 'deviceFilters',
    analysisDownloadButton: 'analysisDownloadButton',
    analysisFilters: 'analysisFilters',
    dateFilters: 'dateFilters',
    dataFilters: 'dataFilters',
    selectionFilters: 'selectionFilters',
    libTableFilters: 'libTableFilters',
    patientSelectionMap: 'patientSelectionMap',
    selectedDataSheet: 'selectedDataSheet',
    libFilters: 'libFilters',
    defaultsFilters: 'defaultsFilters',
    sessions: 'sessions',
    activeSessionId: 'activeSessionId',
    sessionConfig: 'sessionConfig',
};

export const disabledSidebarRoutes = [
    '/settings/data-sheet',
    '/settings/reminder',
    '/notes',
    '/user-setting/',
    '/user-setting/user-info',
]

export const dateRanges = [
    { label: 'Past 14 Days', days: 14, value: 1 },
    { label: 'Past 1 Month', months: 1, value: 2 },
    { label: 'Past 3 Months', months: 3, value: 3 },
    { label: 'Past 6 Months', months: 6, value: 4 },
    { label: 'Max Date', max: true, value: 5 },
    { label: 'Custom Date', custom: true, value: 6 },
];

export const elementsOptions = [
    { name: 'graphTitles', label: 'Graph Titles', checked: true },
    { name: 'lineTitles', label: 'Line Titles', checked: false },
    { name: 'legend', label: 'Legend', checked: true },
    { name: 'dataValues', label: 'Data Values', checked: false },
    { name: 'axisLabelsX', label: 'X-Axis Labels', checked: true },
    { name: 'axisLabelsY', label: 'Y-Axis Labels', checked: true },
    { name: 'dataType', label: 'Data Types', checked: true },
    { name: 'phaseConditionLines', label: 'Phase/Condition Lines', checked: true },
    { name: 'annotations', label: 'Annotations', checked: true }
];

export const sheetNames = {
    notes: 'notes',
    probe: 'probe',
    text: 'text',
}

export const subscriptionStatuses = {
    future: 'future',               // The subscription is scheduled to start at a future date.
    in_trial: 'in_trial',           // The subscription is in trial.
    active: 'active',               // The subscription is active and will be charged for automatically based on the items in it.
    non_renewing: 'non_renewing',   // The subscription will be canceled at the end of the current term.
    paused: 'paused',               // The subscription is paused. The subscription will not renew while in this state.
    cancelled: 'cancelled',         // The subscription has been canceled and is no longer in service.
};

export const roleNames = {
    SUPER_ADMIN: 'SuperAdmin',
    ORG_ADMIN: 'Org. Admin',
}

export const pageNames = [
    { name: 'Program', path: '/', permission: access.targets.view, showClients: true },
    { name: 'Data', path: '/data', permission: access.data.view, showClients: true },
    { name: 'Analysis', path: '/analysis', permission: access.analysis.view, showClients: true },
    { name: 'Users', path: '/admin/user', permission: access.users.view, showClients: false },
    { name: 'Clients', path: '/admin/client', permission: access.clients.view, showClients: false },
]

export const emptyAnnotationPhaseString = 'N/A';

export const targetTrialLimits = [
    {
      limit: 34,
      color: 'red',
    },
    {
      limit: 67,
      color: 'orange',
    },
    {
      limit: 100,
      color: 'limegreen',
    },
    {
      limit: 100,
      color: 'green',
    },
  ];

  export const unsupportedVideoTypes = ['mov'];

  export const masteryLineUnitNames = {
    number: 'Number',
    percentage: 'Percentage',
    seconds: 'Seconds',
    minutes: 'Minutes',
    numberPerMinute: 'Number per minute',
    numberPerHour: 'Number per hour'
  }

  export const masteryLineUnits = {
    category: null,
    correctIncorrect: [masteryLineUnitNames.number, masteryLineUnitNames.percentage],
    duration: [masteryLineUnitNames.seconds, masteryLineUnitNames.minutes],
    frequency: [masteryLineUnitNames.number],
    imageVideo: null,
    interval: [masteryLineUnitNames.number, masteryLineUnitNames.percentage],
    probe: [masteryLineUnitNames.percentage],
    prompt: [masteryLineUnitNames.number, masteryLineUnitNames.percentage],
    quantity: [masteryLineUnitNames.number],
    rate: [masteryLineUnitNames.numberPerMinute, masteryLineUnitNames.numberPerHour],
    rating: [masteryLineUnitNames.number],
    text: null
  };

  export const maxVideoTimeLimitInSessions = 30; //in seconds

  export const sessionDataSyncInterval = 5 * 60 * 1000; //in milli seconds
  export const sessionDataSyncRetryInterval = 10 * 1000; //in milli seconds