import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Collapse,
    Select,
    MenuItem,
    InputLabel,
    TextField,
    Grid,
    IconButton,
    FormHelperText,
    InputAdornment,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    Box,
    Tooltip,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import cloneDeep from 'lodash.clonedeep';
import debounce from 'awesome-debounce-promise';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import {
    phases,
    dataTypes,
    dataTypeDict,
    statuses,
    maintenanceSettingTypes,
    maintenanceSettingTypeEnum,
} from '../../constants';
import PromptData, { defaultPrompt } from './TargetDataForm/PromptData';
import RatingScaleData, { defaultRatingScale } from './TargetDataForm/RatingScaleData';
import TextData, { defaultText } from './TargetDataForm/TextData';
import IntervalData, {
    defaultInterval,
    convertDurationMaskedToSeconds, convertScheduleMaskedToSeconds,
} from './TargetDataForm/IntervalData'
import ProbeData, { defaultProbe } from './TargetDataForm/ProbeData';
import CategoryData, { defaultCategory } from './TargetDataForm/CategoryData';
import CalendarIcon from '../../resources/CalendarIconSVG';
import DisabledCalendarIcon from '../../resources/CalendarIconDisabledSVG';
import { getTarget } from '../../services/TargetService';
import { setLoading } from '../../store/general/actions';
import RichEditor from '../../assets/rte/RichEditor';
import checkDuplicates from '../../utils/checkDuplicates';
import { masteryLineUnitNames } from '../../constants';
import { masteryLineUnits } from '../../constants';
import { setGlobalDialog, closeGlobalDialog } from "../../store/general/actions";
import { 
    hasModifiedCustomData, 
    adjustDataForNewAndHistoricalModification, 
    adjustDataForOnlyNewModification, 
    assignIdsToCustomData 
} from '../../utils/targetCustomDataFormatter';

const useStyles = makeStyles((theme) => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
    },
    input: {
        width: '100%',
    },
    inputSmall: {
        width: '90%',
    },
    instructionField: {
        height: 450,
        maxHeight: 450,
    },
    descriptionField: {
        height: 230,
        maxHeight: 230,
    },
    saveBtn: {
        padding: '6px 20px',
    },
    targetBtn: {
        padding: '6px 20px',
        whiteSpace: 'nowrap',
    },
    selectMenu: {
        textTransform: 'capitalize',
    },
    additionalDataWrapperOuter: {
        padding: '0px 0px',
    },
    dateInput: {
        color: '#53545E',
    },
    dateAdornment: {
        margin: 0,
        padding: 0,
    },
    clearIcon: {
        color: '#000000',
    },
    phasesToggle: {
        fontSize: 12,
        color: '#0FB99E',
        cursor: 'pointer',
        display: 'block',
        height: 14,
        padding: '2px 0px',
    },
    deleteBtn: {
        padding: '4px',
        color: 'rgba(255, 0, 0, 30%)',
        marginLeft: 5,
        marginTop: 5.5,
        '&:hover': { color: 'red' },
    },
    dialogPaper: {
        width: 900,
        overflowX: 'hidden',
    },
    dialogTitle: {
        '@media (max-width: 768px)': {
            paddingLeft: 20,
            paddingRight: 20,
        }
    },
    dialogContent: {
        overflowY: 'auto',
        paddingBottom: 10,

        '@media (max-width: 768px)': {
            paddingLeft: 20,
            paddingRight: 20,
        },

        '& label': {
            height: '20px',
        }
    },
    importLibraryWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '50px',

        '& .MuiInputLabel-root': {
            marginBottom: 0,
        },
    },
    importBtn: {
        marginLeft: '10px',
        height: '30px',

        [theme.breakpoints.down('md')]: {
            fontSize: 12,
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
        },

        [theme.breakpoints.down('xs')]: {
            fontSize: 8,
        },
    },
    formControlLabelText: {
        color: '#000',
        display: 'block',
        fontSize: '14px',
        fontWeight: 500,
    },
    inputLabel: {
        margin: 0,
        marginBottom: '16px',
    },
    inputWithExtraBottomSpace: {
        marginBottom: '20px',
    },
    maintenanceIntevalHelpText : {
        padding: '0px 8px 8px 8px !important',
        marginTop: '-20px'
    },
    infoIcon: {
        left: '5px',
        top: '-5px',
        position: 'relative',
        color: '#0FB99E'
    },
    noLabelInput: {
        paddingTop: '37px !important',
    },
    labelContainer: {
        marginBottom: -18,
        paddingBottom: '0 !important',
        marginTop: 8,
    },
    multiFieldContainer: {
        marginLeft: -2,
    },
    grayedOutLabel: {
        color: 'rgba(0, 0, 0, 0.38)',
    },
    modificationConfirmation: {
        paddingLeft: 0,
        paddingRight: 0
    },
    modificationConfirmationButton: {
        marginRight: 38,
    },
    statusFieldContainer: {
        paddingRight: '12px !important',
    },
    targetAppearFieldContainer: {
        paddingLeft: '12px !important',
    }
}));

const defaultTarget = {
    name: '',
    dataType: 0,
    phase: 0,
    status: '',
    prompts: defaultPrompt,
    ratingScales: defaultRatingScale,
    texts: defaultText,
    interval: defaultInterval,
    probe: defaultProbe,
    categories: defaultCategory,
    programId: 0,
    maximumNumberTrials: '',
    numberTrials: '',
    maintenanceSetting: {
        type: maintenanceSettingTypes[0],
        interval: 1,
        reappearIfUncollected: true,
    },
};

const NewTarget = (props) => {
    const classes = useStyles();
    const {
        onClose,
        open,
        addTarget,
        selection,
        programs,
        fromLibrary,
        libraries,
        user,
        patient,
        target,
        loading,
        setLoading,
        isFormDisabled,
        grayedOutFields,
        isMobile
    } = props;
    const [allPrograms, setAllPrograms] = useState([]);
    const [newTarget, setNewTarget] = useState(cloneDeep(defaultTarget));
    const [newInstructions, setNewInstructions] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [masteryLine, setMasteryLine] = useState({});
    const [cachedMasteryLine, setCachedMasteryLine] = useState(null);
    const [error, setError] = useState(false);
    const [itemError, setItemError] = useState({});
    const [disableButton, setDisableButton] = useState(true);
    const [isActiveTarget, setIsActiveTarget] = useState(null);

    useEffect(() => {
        if (open && !target) {
            let programId = 0;
            if (!!props.program) {
                programId = props.program;
            } else if (selection.program !== 'all') {
                programId = selection.program;
                // if program isn't in the list, it's most likely an archived program
                if (!allPrograms.find((program) => program.id === programId)) {
                    programId = 0;
                }
            }

            const defaultPromptData = user.settings?.defaultPrompt || {};
            const maintenanceSetting = user.settings?.maintenanceSetting || {};

            // Prompt setting is also applied to Interval and Probe data formats
            const updatedDefaultTarget = cloneDeep(defaultTarget);
            if (defaultPromptData.prompts) {
                const formattedDefaultPromptData = defaultPromptData.prompts.map((item) => ({
                    correctness: !item.isNegative,
                    code: item.acronym,
                    codeDesc: item.name,
                }));
                updatedDefaultTarget.interval.codes = formattedDefaultPromptData;
                updatedDefaultTarget.probe.codes = formattedDefaultPromptData;
                updatedDefaultTarget.prompts.codes = formattedDefaultPromptData;
            }

            setNewInstructions('');
            setNewDescription('');
            setMasteryLine({});
            setNewTarget({
                ...cloneDeep(updatedDefaultTarget),
                maintenanceSetting: { ...cloneDeep(maintenanceSetting) },
                programId,
            });
            setError(false);
            setItemError({});
        }
    }, [open]);

    useEffect(() => {
        if (open && target?.id) {
            setLoading('targetForm');
            getTarget(target.id).then((res) => {
                const baseTarget = res.data.data;
                baseTarget.prompts = cloneDeep(defaultPrompt);
                baseTarget.texts = cloneDeep(defaultText);
                baseTarget.interval = cloneDeep(defaultInterval);
                baseTarget.probe = cloneDeep(defaultProbe);
                baseTarget.ratingScales = cloneDeep(defaultRatingScale);
                baseTarget.categories = cloneDeep(defaultCategory);
                if (baseTarget.customData) {
                    if (baseTarget.dataType === 'prompt')
                        baseTarget.prompts = baseTarget.customData;
                    if (baseTarget.dataType === 'text') {
                        baseTarget.texts = baseTarget.customData.map(item => {
                            return (typeof item === 'string') ? {value: item, isRequired: false} : item;
                        });
                    };
                    if (baseTarget.dataType === 'interval') {
                        baseTarget.interval = {
                            ...baseTarget.customData,
                            duration: `${Math.floor(baseTarget.customData.duration / 3600)
                                .toString()
                                .padStart(2, '0')} hours ${Math.floor((baseTarget.customData.duration % 3600) / 60)
                                .toString()
                                .padStart(2, '0')} minutes`,
                            schedule: `${Math.floor(baseTarget.customData.schedule / 60)
                                .toString()
                                .padStart(2, '0')} minutes ${(baseTarget.customData.schedule % 60)
                                .toString()
                                .padStart(2, '0')} seconds`,
                        };
                    }
                    if (baseTarget.dataType === 'probe') {
                        baseTarget.probe = {
                            ...baseTarget.customData,
                        };
                    }
                    if (baseTarget.dataType === 'rating') {
                        baseTarget.ratingScales = cloneDeep(baseTarget.customData);
                    }
                    if (baseTarget.dataType === 'category') {
                        baseTarget.categories = cloneDeep(baseTarget.customData);
                    }
                }

                if (baseTarget.annotations?.length) {
                    const masteryLines = baseTarget.annotations.filter(e => e.type === "masteryLine");
                    if (masteryLines.length && masteryLines[0].data) {
                        setMasteryLine(masteryLines[0].data);
                    } else {
                        setMasteryLine({});
                    }
                } else {
                    setMasteryLine({});
                }

                setNewTarget({
                    ...baseTarget,
                    programId: baseTarget.program?.id,
                    masteredAt: baseTarget.masteredAt || null,
                    maintenanceSetting: {
                        ...(baseTarget.maintenanceSetting ||
                            user.settings?.maintenanceSetting ||
                            defaultTarget.maintenanceSetting),
                    },
                });
                setNewInstructions(baseTarget.instructions);
                setNewDescription(baseTarget.description);
                setError(false);
                setItemError({});
                setLoading('targetForm', false);
            });
        }
    }, [open]);

    useEffect(() => {
        setDisableButton(false);
    }, [open]);

    useEffect(() => {
        if (fromLibrary) {
            const filteredLibraries = isFormDisabled ? libraries : libraries?.filter((library) => library.owner?.id === user.id);
            setAllPrograms(filteredLibraries?.map(e => e.program));
        } else {
            const filteredPrograms = programs?.filter((program) => program.status === 'active') || [];
            setAllPrograms(filteredPrograms);
        }
    }, [programs, libraries, fromLibrary, isFormDisabled]);

    const handleCancel = () => {
        onClose();
    };

    const getMasteryLineYValueError = () => {
        if (masteryLine.unit === masteryLineUnitNames.percentage && (isNaN(masteryLine.yValue) || masteryLine.yValue < 0 || masteryLine.yValue > 100)) {
            return 'Should be in between 0 and 100 when the unit is "Percentage"';
        }
        else if ((masteryLine.unit === masteryLineUnitNames.seconds 
            || masteryLine.unit === masteryLineUnitNames.minutes 
            || masteryLine.unit === masteryLineUnitNames.numberPerMinute 
            || masteryLine.unit === masteryLineUnitNames.numberPerHour) 
            && (isNaN(masteryLine.yValue) || masteryLine.yValue < 0)) {
                return `Should be a non negative number when the unit is "${masteryLine.unit}"`;
        }
        return '';
    }

    const handleChange = (field) => (event) => {
        // trial numbers from setting are applied to prompt and correctIncorrect data types only
        let tempTrialNumbers = null;
        if (field === 'dataType' && event.target.value) {
            if (
                (event.target.value === 'prompt' || event.target.value === 'correctIncorrect') &&
                user.settings?.trialNumber
            ) {
                tempTrialNumbers = user.settings?.trialNumber;
            } else {
                tempTrialNumbers = { maximumNumberTrials: '', numberTrials: '' };
            }
            
            const defaultMasteryLineSetting = user.settings?.masteryLineSetting || {};
            if (!target && defaultMasteryLineSetting?.yValue && defaultMasteryLineSetting?.unit) {
                const unitSupported = masteryLineUnits[event.target.value]?.includes(defaultMasteryLineSetting.unit);
                if (unitSupported) {
                    setMasteryLine(defaultMasteryLineSetting);
                } else {
                    setMasteryLine({});
                }
            }
            if (target && (event.target.value === 'imageVideo' || event.target.value === 'category'  || event.target.value === 'text')) {
                !cachedMasteryLine && setCachedMasteryLine(masteryLine);
                setMasteryLine({});
            } else if (target && cachedMasteryLine) {
                setMasteryLine(cachedMasteryLine);
                setCachedMasteryLine(null);
            }
            if (!(event.target.value === 'frequency' || event.target.value === 'duration' || event.target.value === 'quantity')) {
                setNewTarget({ ...newTarget, [field]: event.target.value, isAutoZero: false, ...tempTrialNumbers });
                return;
            }
        }

        if (event.target.type === 'number') {
            let numberVal = event.target.value;
            if (event.target.value !== '') {
                numberVal = Math.floor(Number(event.target.value)) ?? event.target.value;
            }
            setNewTarget({
                ...newTarget,
                [field]: numberVal,
            });
            return;
        }

        if (field === 'isAutoZero') {
            setNewTarget({
                ...newTarget,
                [field]: event.target.checked,
            });
            return;
        }

        setNewTarget({ ...newTarget, [field]: event.target.value, ...tempTrialNumbers });
    };

    const handleChangeMasteryLine = (field) => (event) => {
        if (field === 'yValue') {
            setMasteryLine({ ...masteryLine, yValue: event.target.value });
        } else if (field === 'unit') {
            setMasteryLine({ ...masteryLine, unit: event.target.value });
        }
    };

    const handleChangeRte = (field) => (value) => {
        setNewInstructions(value);
    };

    const handleChangeDescription = (field) => (event) => {
        setNewDescription(event.target.value);
    };

    const handleChangeDate = (field) => (date) => {
        setNewTarget({ ...newTarget, [field]: date });
    };

    const handleChangeMaintenanceSetting = (field) => (event) => {
        const maintenanceSetting = newTarget.maintenanceSetting || {};
        if (field === 'reappearIfUncollected') {
            setNewTarget({
                ...newTarget,
                maintenanceSetting: { ...maintenanceSetting, [field]: event.target.checked },
            });
            return;
        }
        const value =
            event.target.type === 'number'
                ? Math.floor(Number(event.target.value))
                : event.target.value;
        setNewTarget({
            ...newTarget,
            maintenanceSetting: { ...maintenanceSetting, [field]: value },
        });
    };

    const validateMasteredAndOpenDateRange = () => {
        return !(newTarget.openedAt 
            && newTarget.masteredAt 
            && new Date(newTarget.openedAt) > new Date(newTarget.masteredAt));
    }

    const getModificationOptionAndSave = (data) => {
        props.setGlobalDialog(
            "Data Modification",
            "Apply Changes To?",
            (answer) => {},
            <DialogActions className={classes.modificationConfirmation}>
              <Button id="target-form-confirm-new-data-only" className={classes.modificationConfirmationButton} onClick={() => {
                addTarget(assignIdsToCustomData(adjustDataForOnlyNewModification(data)));
                props.closeGlobalDialog();
              }} autoFocus>
                New Data Only
              </Button>
              <Button id="target-form-confirm-new-and-historical-data" onClick={() => {
                addTarget(assignIdsToCustomData(adjustDataForNewAndHistoricalModification(data)));
                props.closeGlobalDialog();
              }} autoFocus>
                New and Historical Data
              </Button>
            </DialogActions>,
            false
          );
    }

    const isTargetTrialsGreaterThanMaxTrials = () => {
        return (newTarget.numberTrials || newTarget.numberTrials === 0)
            && (newTarget.maximumNumberTrials || newTarget.maximumNumberTrials === 0)
            && +newTarget.numberTrials > +newTarget.maximumNumberTrials;
    }

    const handleAddActivate = (activate, isEdit) => {
        setDisableButton(true);
        const isActive = (activate || newTarget.status === 'active') ? true : false;
        setIsActiveTarget(isActive);
        let error = false;
        const newItemError = {};

        if (
            (!fromLibrary && !newTarget.programId) ||
            !newTarget.name.trim() ||
            (!newTarget.phase && isActive) ||
            !newTarget.dataType ||
            (!!target && !fromLibrary && !newTarget.openedAt && isActive) ||
            (!!target && !fromLibrary && !newTarget.status)
        ) {
            error = true;
        }

        let data = {
            ...newTarget,
            name: newTarget.name.trim(),
            numberTrials:
                newTarget.numberTrials === 0 || newTarget.numberTrials === ''
                    ? null
                    : newTarget.numberTrials,
            maximumNumberTrials:
                newTarget.maximumNumberTrials === 0 || newTarget.maximumNumberTrials === ''
                    ? null
                    : newTarget.maximumNumberTrials,
        };

        if (!isEdit) {
            data.status = activate ? 'active' : 'inactive';
            data.branchId = patient.branch.id;
            data.openedAt = activate ? new Date().toISOString() : null;
        } else {
            data.id = target.id;
        }

        /*
            Clear the mastered masteredAt date in this case. 
            There should be no target with status != mastered and with mastered date not null
        */
        if(isEdit && newTarget.status !== 'mastered' && newTarget.masteredAt !== null){
            data.masteredAt = null;
        } 

        if (newTarget.dataType === 'prompt') {
            const codeArray = newTarget.prompts.codes.filter(e => !e.isDeleted).map((e) => e.code.trim());
            newTarget.prompts.codes.filter(e => !e.isDeleted).forEach((item, index) => {
                item.code = item.code.trim();
                item.codeDesc = item.codeDesc.trim();
                if (!item.codeDesc || !item.code || checkDuplicates(codeArray, item.code.trim())) {
                    error = true;
                    newItemError[`prompt ${index}`] = true;
                }
            });
            data.customData = newTarget.prompts;
        }
        if (newTarget.dataType === 'interval') {
            const interval = newTarget.interval;

            const duration = convertDurationMaskedToSeconds(interval.duration);
            const schedule = convertScheduleMaskedToSeconds(interval.schedule);

            if (duration === 0 || schedule === 0 || isNaN(duration) || isNaN(schedule)) {
                error = true;
            } else if (schedule > duration) {
                error = true;
            }

            const codeArray = interval.codes.filter(e => !e.isDeleted).map((e) => e.code.trim());
            interval.codes.filter(e => !e.isDeleted).forEach((item, index) => {
                item.code = item.code.trim();
                item.codeDesc = item.codeDesc.trim();
                if (!item.code || !item.codeDesc || checkDuplicates(codeArray, item.code)) {
                    error = true;
                    newItemError[`interval ${index}`] = true;
                }
            });

            data.customData = {
                ...interval,
                duration,
                schedule,
            };
        }
        if (newTarget.dataType === 'text') {
            const textArray = newTarget.texts.filter(e => !e.isDeleted).map((e) => e.value.trim());
            newTarget.texts.filter(e => !e.isDeleted).forEach((item, index) => {
                const text = item.value.trim();
                if (typeof item.isRequired === 'undefined') {
                    item.isRequired = false;
                }
                if (!text || checkDuplicates(textArray, text)) {
                    error = true;
                    newItemError[`text ${index}`] = true;
                }
            });
            data.customData = newTarget.texts;
        }

        if (newTarget.dataType === 'category') {
            const categoryArray = newTarget.categories.filter(e => !e.isDeleted).map((e) => e.category.trim());
            newTarget.categories.filter(e => !e.isDeleted).forEach((category, index) => {
                const categoryText = category.category.trim();
                const categoryResponse = !category.responses.filter(e => !e.isDeleted).some(item => item.label.trim() === '')
                if (!categoryText || !categoryResponse || checkDuplicates(categoryArray, categoryText)) {
                    error = true;
                    newItemError[`category ${index}`] = true;
                }
            });
            data.customData = newTarget.categories;
        }

        if (newTarget.dataType === 'rating') {
            const ratingScalesQuestions = newTarget.ratingScales.filter(e => !e.isDeleted).map((e) => e.question.trim());
            ratingScalesQuestions.filter(e => !e.isDeleted).forEach((q, i) => {
                if (!q) {
                    error = true;
                    newItemError[`ratingScale ${i}`] = true;
                }
            });
            const ratingsArray = newTarget.ratingScales.filter(e => !e.isDeleted).map((e) => e.ratings);
            ratingsArray.forEach((rating, i) => {
                rating.filter(e => !e.isDeleted).forEach((r, ii) => {
                    if (rating.filter((f) => (!f.isDeleted && f.value === r.value)).length > 1) {
                        error = true;
                        newItemError[`ratingScale ${i} ${ii}`] = true;
                    }
                });
            });
            data.customData = newTarget.ratingScales;
        }

        if (isTargetTrialsGreaterThanMaxTrials()) {
            error = true;
        }

        if (!validateMasteredAndOpenDateRange()) {
            error = true;
        }

        if (
            (newTarget.numberTrials && newTarget.numberTrials < 0) ||
            (newTarget.maximumNumberTrials && newTarget.maximumNumberTrials < 0) ||
            (newTarget.phase === 'maintenance' &&
                newTarget.maintenanceSetting &&
                newTarget.maintenanceSetting?.interval <= 0)
        ) {
            error = true;
        }

        if (newTarget.dataType === 'probe') {
            const codes = newTarget.probe.codes.filter(e => !e.isDeleted).map((item) => item.code.trim());
            newTarget.probe.codes.filter(e => !e.isDeleted).forEach((item, index) => {
                item.code = item.code.trim();
                if (!item.code || !item.codeDesc || checkDuplicates(codes, item.code)) {
                    error = true;
                    newItemError[`probe code ${index}`] = true;
                }
            });

            const setArray = newTarget.probe.sets.filter(e => !e.isDeleted).map((e) => e.value.trim());
            newTarget.probe.sets.filter(e => !e.isDeleted).forEach((set, index) => {
                if (typeof set.isRequired === 'undefined') {
                    set.isRequired = false;
                }
                if (!set.value || checkDuplicates(setArray, set.value.trim())) {
                    error = true;
                    newItemError[`probe set ${index}`] = true;
                }
            });

            data.customData = newTarget.probe;
        }

        if (newTarget.dataType === 'text' || newTarget.dataType === 'category' || newTarget.dataType === 'imageVideo') {
            const masteryLines = data.annotations?.filter(e => e.type === "masteryLine");
            if (masteryLine?.length) {
                masteryLines.forEach(e => {e.delete = true});
            }
        } else if ((getMasteryLineYValueError() || (masteryLine?.yValue && !masteryLineUnits[newTarget.dataType]?.includes(masteryLine?.unit)))) {
            error = true;
        } else if (masteryLine?.yValue && (!isEdit  || (isEdit && !data.annotations?.length))) {
            data.annotations = [
                {
                    "type": "masteryLine",
                    "data": {
                        "yValue": masteryLine?.yValue,
                        "unit": masteryLine?.unit
                    }
                }
            ];
        } else if (isEdit && data.annotations?.length) {
            const masteryLines = data.annotations.filter(e => e.type === "masteryLine");
            if (masteryLines.length && masteryLine?.yValue) {
                masteryLines[0].data = masteryLine;
            } else if (masteryLines.length && !masteryLine?.yValue) {
                const mainMasteryLine = data.annotations.find(e => e.type === "masteryLine");
                mainMasteryLine.delete = true;
            } else if (masteryLine?.yValue) {
                data.annotations.push({
                    "type": "masteryLine",
                    "data": {
                        "yValue": masteryLine?.yValue,
                        "unit": masteryLine?.unit
                    }
                });
            }
        } 

        if (error) {
            setError(true);
            setItemError(newItemError);
            setDisableButton(false);
            return;
        }

        data = {
            ...data,
            interval: undefined,
            prompts: undefined,
            ratingScales: undefined,
            texts: undefined,
            probe: undefined,
            categories: undefined,
            instructions: newInstructions,
            description: newDescription,
            maintenanceSetting: data.phase === 'maintenance' ? data.maintenanceSetting : undefined,
        };

        if (hasModifiedCustomData(data) && targetHasData) {
            getModificationOptionAndSave(data);
        } else if (hasModifiedCustomData(data) && !targetHasData) {
            addTarget(assignIdsToCustomData(adjustDataForNewAndHistoricalModification(data)));
        } else {
            addTarget(assignIdsToCustomData(data));
        }
    };

    // Added to avoid double clicking save button creating two targets
    const debouncedHandleAddActivate = debounce(handleAddActivate, 500);

    const targetHasData = (!!newTarget && !!newTarget.targetDataCount) || (!!target && !!target.targetDataCount);
    const grayOutBaseline = newTarget.dataType === 'text' || newTarget.dataType === 'category' || newTarget.dataType === 'imageVideo';
    const grayOutZeroOut = !(newTarget.dataType === 'frequency' || newTarget.dataType === 'duration' || newTarget.dataType === 'quantity');
    return (
        <Dialog
            disableBackdropClick
            disableEnforceFocus
            disableEscapeKeyDown
            maxWidth='md'
            open={open}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogTitle className={classes.dialogTitle}>
                <Grid container>
                    <Grid item xs={6}>
                        {!!target ? 'Edit' : 'Create'} Target
                    </Grid>
                    <Grid item xs={6} container justify='flex-end'>
                        <IconButton id="target-form-close" className={classes.closeIconBtn} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                {loading.targetForm || loading.targetFormLib ? (
                    <Grid container justify='center' alignItems='center' style={{ height: 600 }}>
                        <CircularProgress size={200} />
                    </Grid>
                ) : (
                    <Grid container spacing={2} justify='center' alignContent='stretch'>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <InputLabel id="target-form-field-program-label" required>Program</InputLabel>
                            <Select
                                id="target-form-field-program"
                                variant='outlined'
                                IconComponent={KeyboardArrowDownIcon}
                                className={classes.input}
                                value={newTarget.programId}
                                onChange={handleChange('programId')}
                                error={error && !newTarget.programId}
                                disabled={isFormDisabled}
                            >
                                <MenuItem disabled value={0}>
                                    Choose
                                </MenuItem>
                                {allPrograms.map((prog) => (
                                    <MenuItem key={prog.id} value={prog.id}>
                                        {prog.name}
                                    </MenuItem>
                                ))}
                                {props.program &&
                                    !allPrograms.find((e) => e.id === props.program) && (
                                        <MenuItem value={props.program}>
                                            Loading new program...
                                        </MenuItem>
                                    )}
                            </Select>
                            <FormHelperText id="target-form-field-program-helper-text" error={error && !newTarget.programId}>
                                {error && !newTarget.programId
                                    ? 'Please choose a program'
                                    : ' '}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 3}>
                            <InputLabel id="target-form-field-no-trials-label">Target Number of Trials</InputLabel>
                            <TextField
                                id="target-form-field-no-trials"
                                variant='outlined'
                                type='number'
                                value={newTarget.numberTrials?.toString()}
                                onChange={handleChange('numberTrials')}
                                className={classes.input}
                                InputProps={{ inputProps: { min: 0 } }}
                                disabled={isFormDisabled}
                            />
                            <FormHelperText
                                id="target-form-field-no-trials-help-text"
                                error={
                                    (error && isTargetTrialsGreaterThanMaxTrials()) ||
                                    (error &&
                                        +newTarget.numberTrials &&
                                        +newTarget.numberTrials < 0)
                                }
                            >
                                {error && isTargetTrialsGreaterThanMaxTrials()
                                    ? 'Please choose a value lower than maximum number of trials'
                                    : ' '}
                                {error && +newTarget.numberTrials && +newTarget.numberTrials < 0
                                    ? 'Negative value is not allowed'
                                    : ' '}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 3}>
                            <InputLabel id="target-form-field-max-trials-label">Maximum Number of Trials</InputLabel>
                            <TextField
                                id="target-form-field-max-trials"
                                variant='outlined'
                                type='number'
                                value={newTarget.maximumNumberTrials?.toString()}
                                onChange={handleChange('maximumNumberTrials')}
                                className={classes.input}
                                InputProps={{ inputProps: { min: 0 } }}
                                disabled={isFormDisabled}
                            />
                            <FormHelperText
                                id="target-form-field-max-trials-help-text"
                                error={
                                    error &&
                                    newTarget.maximumNumberTrials &&
                                    newTarget.maximumNumberTrials < 0
                                }
                            >
                                {error &&
                                newTarget.maximumNumberTrials &&
                                newTarget.maximumNumberTrials < 0
                                    ? 'Negative value is not allowed'
                                    : ' '}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <InputLabel id="target-form-field-target-name-label" required>Target Name</InputLabel>
                            <TextField
                                id="target-form-field-target-name"
                                variant='outlined'
                                value={newTarget.name}
                                onChange={handleChange('name')}
                                className={classes.input}
                                error={error && !newTarget.name.trim()}
                                helperText={
                                    error && !newTarget.name.trim() ? "Name can't be empty" : ' '
                                }
                                disabled={isFormDisabled}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 3}>
                            <InputLabel id="target-form-field-baseline-label" className={grayOutBaseline ? classes.grayedOutLabel : ''}>
                                Baseline / Mastery Line
                            </InputLabel>
                            <TextField
                                id="target-form-field-baseline"
                                variant='outlined'
                                type='number'
                                value={masteryLine?.yValue || ''}
                                onChange={handleChangeMasteryLine('yValue')}
                                placeholder="Y-Value"
                                className={classes.input}
                                InputProps={{ inputProps: { min: 0 } }}
                                error={error && getMasteryLineYValueError()}
                                helperText={
                                    error && (getMasteryLineYValueError() || ' ')
                                }
                                disabled={isFormDisabled || grayOutBaseline}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 3} className={classes.noLabelInput}>
                            <Select
                                id="target-form-field-unit"
                                variant='outlined'
                                IconComponent={KeyboardArrowDownIcon}
                                className={classes.input}
                                error={error && masteryLine?.yValue && !masteryLineUnits[newTarget?.dataType]?.includes(masteryLine?.unit)}
                                disabled={isFormDisabled || grayOutBaseline}
                                value={masteryLine?.unit || 0}
                                onChange={handleChangeMasteryLine('unit')}
                            >
                                <MenuItem value={0}>Unit Unselected</MenuItem>
                                {newTarget?.dataType && masteryLineUnits[newTarget?.dataType]?.length && masteryLineUnits[newTarget?.dataType].map(line => (
                                    <MenuItem key={line} classes={{ root: classes.selectMenu }} value={line}>{line}</MenuItem>
                                ))}
                            </Select>
                            {!isFormDisabled && !grayOutBaseline && <FormHelperText id="target-form-field-unit-helper-text" error={error && masteryLine?.yValue && !masteryLineUnits[newTarget?.dataType]?.includes(masteryLine?.unit)}>
                                {(error && masteryLine?.yValue && !masteryLineUnits[newTarget?.dataType]?.includes(masteryLine?.unit))
                                    ? 'Please select a unit'
                                    : ' '}
                            </FormHelperText>}
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Grid container xs={12}>
                                <InputLabel id="target-form-field-description-label">Target Description</InputLabel>
                                <Tooltip title='Target Description can be displayed under Target Name on the data collection screen of the mobile/tablet app'>
                                    <InfoIcon className={classes.infoIcon} />
                                </Tooltip>
                            </Grid>
                            <TextField
                                id="target-form-field-description"
                                variant='outlined'
                                value={newDescription}
                                onChange={handleChangeDescription('description')}
                                className={`${classes.input} ${classes.inputWithExtraBottomSpace}`}
                                inputProps={{maxlength: 60}}
                                disabled={isFormDisabled}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 3}>
                            <InputLabel id="target-form-field-date-opened-label" className={grayedOutFields?.includes('openedAt') ? classes.grayedOutLabel : ''}>
                                Date Opened
                            </InputLabel>
                            <KeyboardDatePicker
                                id="target-form-field-date-opened"
                                autoOk
                                format='MM/dd/yyyy'
                                className={classes.input}
                                inputVariant='outlined'
                                value={newTarget.openedAt || null}
                                inputProps={{ className: classes.dateInput }}
                                InputProps={{
                                    classes: {
                                        adornedEnd: classes.dateAdornment,
                                        adornedStart: classes.dateAdornment,
                                    },
                                }}
                                error={error && !grayedOutFields?.includes('openedAt') && !newTarget.openedAt && isActiveTarget}
                                helperText={
                                    error && !grayedOutFields?.includes('openedAt') && !newTarget.openedAt && isActiveTarget ? 'Please choose a date' : ' '
                                }
                                keyboardIcon={grayedOutFields?.includes('openedAt') ? DisabledCalendarIcon : CalendarIcon}
                                InputAdornmentProps={{
                                    position: 'start',
                                    className: classes.dateAdornment,
                                }}
                                onChange={handleChangeDate('openedAt')}
                                disabled={isFormDisabled || grayedOutFields?.includes('openedAt')}
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 3}>
                            <InputLabel id="target-form-field-date-mastered-label" className={grayedOutFields?.includes('masteredAt') ? classes.grayedOutLabel : ''}>
                                Date Mastered
                            </InputLabel>
                            <KeyboardDatePicker
                                id="target-form-field-date-mastered"
                                autoOk
                                format='MM/dd/yyyy'
                                className={classes.input}
                                inputVariant='outlined'
                                value={newTarget.masteredAt || null}
                                inputProps={{ className: classes.dateInput }}
                                InputProps={{
                                    classes: {
                                        adornedEnd: classes.dateAdornment,
                                        adornedStart: classes.dateAdornment,
                                    },
                                    endAdornment: (isFormDisabled || grayedOutFields?.includes('masteredAt')) ? null : (
                                        <InputAdornment>
                                            <IconButton
                                                id="target-form-field-date-mastered-clear" 
                                                onClick={() =>
                                                    setNewTarget({
                                                        ...newTarget,
                                                        masteredAt: null,
                                                    })
                                                }
                                            >
                                                <CloseIcon className={classes.clearIcon} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                keyboardIcon={grayedOutFields?.includes('openedAt') ? DisabledCalendarIcon : CalendarIcon}
                                InputAdornmentProps={{
                                    position: 'start',
                                    className: classes.dateAdornment,
                                }}
                                onChange={handleChangeDate('masteredAt')}
                                disabled={isFormDisabled || grayedOutFields?.includes('masteredAt')}
                                error={error && !validateMasteredAndOpenDateRange()}
                                helperText={
                                    error && !validateMasteredAndOpenDateRange() && 'Mastered date cannot be earlier than the Opened date'
                                }
                            />
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <InputLabel id="target-form-field-data-type-label" required>Data Type</InputLabel>
                            <Select
                                id="target-form-field-data-type"
                                variant='outlined'
                                IconComponent={KeyboardArrowDownIcon}
                                className={classes.input}
                                error={error && !newTarget.dataType}
                                disabled={targetHasData || isFormDisabled}
                                value={newTarget.dataType}
                                onChange={handleChange('dataType')}
                            >
                                <MenuItem disabled value={0}>
                                    Choose
                                </MenuItem>
                                {dataTypes.map((dataType) => (
                                    <MenuItem key={dataType} value={dataType}>
                                        {dataTypeDict[dataType]}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText id="target-form-field-data-type-help-text" error={error && !newTarget.dataType}>
                                {error && !newTarget.dataType
                                    ? 'Please choose a data type'
                                    : ' '}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6} className={classes.noLabelInput}>
                            <FormControlLabel
                                id="target-form-field-zero-behaviour-label"
                                classes={{
                                    label: classes.formControlLabelText,
                                }}
                                control={
                                    <Checkbox
                                        id="target-form-field-zero-behaviour"
                                        checked={
                                            newTarget?.isAutoZero || false
                                        }
                                        onChange={handleChange(
                                            'isAutoZero'
                                        )}
                                        color='primary'
                                        disabled={isFormDisabled || grayOutZeroOut}
                                    />
                                }
                                label='Record "0" instance of behaviour, if no data was collected during the sessions'
                            />
                        </Grid>
                        <Grid item className={classes.statusFieldContainer} xs={isMobile ? 12 : 6}>
                            <InputLabel
                                id="target-form-field-status-label"
                                required={!grayedOutFields?.includes('status')}
                                className={grayedOutFields?.includes('status') ? classes.grayedOutLabel : ''}
                            >
                                Status
                            </InputLabel>
                            <Select
                                id="target-form-field-status"
                                variant='outlined'
                                IconComponent={KeyboardArrowDownIcon}
                                className={`${classes.input} ${classes.selectMenu}`}
                                value={newTarget.status}
                                onChange={handleChange('status')}
                                error={error && !grayedOutFields?.includes('status') && !newTarget.status}
                                disabled={grayedOutFields?.includes('status')}
                            >
                                <MenuItem disabled value={0}>
                                    Choose
                                </MenuItem>
                                {statuses
                                    .filter((e) => e !== 'all')
                                    .map((status) => (
                                        <MenuItem
                                            key={status}
                                            classes={{ root: classes.selectMenu }}
                                            value={status}
                                        >
                                            {status}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText id="target-form-field-status-help-text" error={error && !grayedOutFields?.includes('status') && !newTarget.status}>
                                {error && !grayedOutFields?.includes('status') && !newTarget.status
                                    ? 'Please choose a status'
                                    : ' '}
                            </FormHelperText>
                        </Grid>
                        <Grid container xs={isMobile ? 12 : 6} spacing={2} className={classes.multiFieldContainer}>
                            <Grid item xs={12} className={classes.labelContainer}>
                                <InputLabel id="target-form-maintenance-target-show-label" className={newTarget.phase !== 'maintenance' ? classes.grayedOutLabel : ''}>
                                    Maintenance targets show up every
                                </InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="target-form-maintenance-target-show-frequency"
                                    variant='outlined'
                                    type='number'
                                    value={
                                        newTarget.maintenanceSetting?.interval?.toString() ||
                                        user.settings?.maintenanceSetting?.interval ||
                                        '1'
                                    }
                                    onChange={handleChangeMaintenanceSetting(
                                        'interval'
                                    )}
                                    className={classes.input}
                                    InputProps={{
                                        inputProps: {
                                            min: 1,
                                            style: { textAlign: 'right' },
                                        },
                                    }}
                                    disabled={isFormDisabled || newTarget.phase !== 'maintenance'}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    id="target-form-maintenance-target-show-sessions"
                                    variant='outlined'
                                    IconComponent={KeyboardArrowDownIcon}
                                    className={classes.input}
                                    error={error && !newTarget.maintenanceSetting?.type}
                                    value={
                                        newTarget.maintenanceSetting?.type ||
                                        user.settings?.maintenanceSetting?.type ||
                                        defaultTarget.maintenanceSetting?.type
                                    }
                                    onChange={handleChangeMaintenanceSetting('type')}
                                    disabled={isFormDisabled || newTarget.phase !== 'maintenance'}
                                >
                                    {maintenanceSettingTypes.map((type) => (
                                        <MenuItem key={type} value={type}>
                                            {maintenanceSettingTypeEnum[type]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12} className={classes.maintenanceIntevalHelpText}>
                                <FormHelperText
                                    id="target-form-maintenance-target-show-help-text"
                                    error={
                                        error &&
                                        newTarget.phase === 'maintenance' &&
                                        newTarget.maintenanceSetting &&
                                        newTarget.maintenanceSetting?.interval <= 0
                                    }
                                >
                                    {error &&
                                    newTarget.phase === 'maintenance' &&
                                    newTarget.maintenanceSetting &&
                                    newTarget.maintenanceSetting?.interval <= 0
                                        ? 'Negative value or 0 is not allowed'
                                        : ' '}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <InputLabel id="target-form-phase-label" required>Phase</InputLabel>
                            <Select
                                id="target-form-phase"
                                variant='outlined'
                                IconComponent={KeyboardArrowDownIcon}
                                className={`${classes.input} ${classes.selectMenu}`}
                                error={error && !newTarget.phase && isActiveTarget}
                                value={newTarget.phase}
                                onChange={handleChange('phase')}
                                disabled={isFormDisabled}
                            >
                                <MenuItem disabled value={0}>
                                    Choose
                                </MenuItem>
                                {phases.map((phase) => (
                                    <MenuItem
                                        key={phase}
                                        classes={{ root: classes.selectMenu }}
                                        value={phase}
                                    >
                                        {phase}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText
                                id="target-form-phase-help-text"
                                className={classes.helperText}
                                error={error && !newTarget.phase && isActiveTarget}
                            >
                                {error && !newTarget.phase && isActiveTarget ? 'Please choose a phase' : ' '}
                            </FormHelperText>
                        </Grid>
                        <Grid item className={classes.targetAppearFieldContainer} xs={isMobile ? 12 : 6}>
                            <FormControlLabel
                                id="target-form-target-appearance-label"
                                classes={{
                                    label: classes.formControlLabelText,
                                }}
                                control={
                                    <Checkbox
                                        id="target-form-target-appearance"
                                        checked={
                                            newTarget.maintenanceSetting
                                                ?.reappearIfUncollected
                                        }
                                        onChange={handleChangeMaintenanceSetting(
                                            'reappearIfUncollected'
                                        )}
                                        color='primary'
                                        disabled={isFormDisabled || newTarget.phase !== 'maintenance'}
                                    />
                                }
                                label='Make the target appear at the next session/day again if no data is collected'
                            />
                        </Grid>

                        {newTarget.dataType === 'prompt' && (
                            <Grid item xs={12}>
                                <Collapse
                                    in={newTarget.dataType === 'prompt'}
                                    className={classes.additionalDataWrapperOuter}
                                >
                                    <PromptData
                                        prompts={{...newTarget.prompts, codes: newTarget.prompts.codes.filter(e => !e.isDeleted)}}
                                        setPrompts={(prompts) => {
                                            const deletedCodes = newTarget.prompts.codes.filter(e => e.isDeleted);
                                            const codes = deletedCodes.concat(prompts.codes);
                                            setNewTarget({ ...newTarget, prompts: { ...prompts, codes } });
                                        }}
                                        itemError={itemError}
                                        disabled={isFormDisabled}
                                    />
                                </Collapse>
                            </Grid>
                        )}

                        {newTarget.dataType === 'rating' && (
                            <Grid item xs={12}>
                                <Collapse
                                    in={newTarget.dataType === 'rating'}
                                    className={classes.additionalDataWrapperOuter}
                                >
                                    <RatingScaleData
                                        ratingScales={
                                            newTarget.ratingScales.filter(e => !e.isDeleted)
                                                .map((ratingScale) => ({...ratingScale, ratings: ratingScale.ratings.filter(e => !e.isDeleted)}))
                                        }
                                        setRatingScales={(ratingScales) => {
                                            const deletedScales = newTarget.ratingScales.filter(e => e.isDeleted);
                                            const newRatingScales = ratingScales.map((scale) => {
                                                if (scale.id) {
                                                    const oldScale = newTarget.ratingScales.find(e => e.id === scale.id);
                                                    if (oldScale.ratings) {
                                                        const deletedRatings = oldScale.ratings.filter(e => e.isDeleted);
                                                        const newRatings = deletedRatings.concat(scale.ratings);
                                                        return { ...scale, ratings: newRatings };
                                                    }
                                                }
                                                return scale;
                                            });
                                            setNewTarget({ ...newTarget, ratingScales: deletedScales.concat(newRatingScales) });
                                        }}
                                        itemError={itemError}
                                        disabled={isFormDisabled}
                                    />
                                </Collapse>
                            </Grid>
                        )}

                        {newTarget.dataType === 'text' && (
                            <Grid item xs={12}>
                                <Collapse
                                    in={newTarget.dataType === 'text'}
                                    className={classes.additionalDataWrapperOuter}
                                >
                                    <TextData
                                        texts={newTarget.texts.filter(e => !e.isDeleted)}
                                        setTexts={(texts) => {
                                            const deletedTexts = newTarget.texts.filter(e => e.isDeleted);
                                            const newTexts = deletedTexts.concat(texts);
                                            setNewTarget({ ...newTarget, texts: newTexts })
                                        }}
                                        itemError={itemError}
                                        disabled={isFormDisabled}
                                    />
                                </Collapse>
                            </Grid>
                        )}

                        {newTarget.dataType === 'category' && (
                            <Grid item xs={12}>
                                <Collapse
                                    in={newTarget.dataType === 'category'}
                                    className={classes.additionalDataWrapperOuter}
                                >
                                    <CategoryData
                                        categories={
                                            newTarget.categories.filter(e => !e.isDeleted)
                                                .map((category) => ({...category, responses: category.responses.filter(e => !e.isDeleted)}))
                                        }
                                        setCategories={(categories) => {
                                            const deletedCategories = newTarget.categories.filter(e => e.isDeleted);
                                            const newCategories = categories.map((category) => {
                                                if (category.id) {
                                                    const oldCategory = newTarget.categories.find(e => e.id === category.id);
                                                    if (oldCategory?.responses) {
                                                        const deletedResponses = oldCategory.responses.filter(e => e.isDeleted);
                                                        const newResponses = deletedResponses.concat(category.responses);
                                                        return { ...category, responses: newResponses };
                                                    }
                                                }
                                                return category;
                                            });
                                            setNewTarget({ ...newTarget, categories: deletedCategories.concat(newCategories) });
                                        }}
                                        itemError={itemError}
                                        disabled={isFormDisabled}
                                    />
                                </Collapse>
                            </Grid>
                        )}

                        {newTarget.dataType === 'interval' && (
                            <Grid item xs={12}>
                                <Collapse
                                    in={newTarget.dataType === 'interval'}
                                    className={classes.additionalDataWrapperOuter}
                                >
                                    <IntervalData
                                        interval={{...newTarget.interval, codes: newTarget.interval.codes.filter(e => !e.isDeleted)}}
                                        setInterval={(interval) => {
                                            const deletedCodes = newTarget.interval.codes.filter(e => e.isDeleted);
                                            const codes = deletedCodes.concat(interval.codes);
                                            setNewTarget({ ...newTarget, interval: { ...interval, codes } });
                                        }}
                                        itemError={itemError}
                                        error={error}
                                        disabled={isFormDisabled}
                                    />
                                </Collapse>
                            </Grid>
                        )}

                        {newTarget.dataType === 'probe' && (
                            <Grid item xs={12}>
                                <Collapse
                                    in={newTarget.dataType === 'probe'}
                                    className={classes.additionalDataWrapperOuter}
                                >
                                    <ProbeData
                                        probe={{ 
                                            ...newTarget.probe, 
                                            codes: newTarget.probe.codes.filter(e => !e.isDeleted),
                                            sets: newTarget.probe.sets.filter(e => !e.isDeleted)
                                        }}
                                        setProbe={(probe) => {
                                            const deletedCodes = newTarget.probe.codes.filter(e => e.isDeleted);
                                            const codes = deletedCodes.concat(probe.codes);
                                            const deletedSets = newTarget.probe.sets.filter(e => e.isDeleted);
                                            const sets = deletedSets.concat(probe.sets);
                                            setNewTarget({ ...newTarget, probe: { ...probe, codes, sets } });
                                        }}
                                        itemError={itemError}
                                        error={error}
                                        disabled={isFormDisabled}
                                    />
                                </Collapse>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Grid container xs={12}>
                                <InputLabel id="target-form-target-instructions-label">Target Instructions</InputLabel>
                                <Tooltip title='Mobile users can see Target Instructions by swiping the target to the left'>
                                    <InfoIcon className={classes.infoIcon} />
                                </Tooltip>
                            </Grid>
                            <RichEditor
                                value={newInstructions}
                                onChange={handleChangeRte('instructions')}
                                className={classes.instructionField}
                                id='target-instructions'
                                disabled={isFormDisabled}
                            />
                        </Grid>
                    </Grid>
                )}
            </DialogContent>

            <DialogActions>
                {!!target ? (
                    (!isFormDisabled && <Grid container>
                        <Grid item xs={12} container justify='flex-end'>
                            <Button
                                id="target-form-action-update"
                                className={classes.targetBtn}
                                disabled={loading.targetForm}
                                onClick={() => debouncedHandleAddActivate(false, true)}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>)
                ) : (
                    <Grid container>
                        <Grid item xs={6}>
                            <Button
                                id="target-form-action-save"
                                disabled={disableButton}
                                className={classes.saveBtn}
                                onClick={() => debouncedHandleAddActivate(false)}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={6} container justify='flex-end'>
                            <Button
                                id="target-form-action-activate"
                                disabled={disableButton}
                                className={classes.targetBtn}
                                onClick={() => debouncedHandleAddActivate(true)}
                            >
                                Save {isMobile ? '&' : 'and'} Activate
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </DialogActions>
        </Dialog>
    );
};

NewTarget.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    addTarget: PropTypes.func.isRequired,
    selection: PropTypes.object.isRequired,
    programs: PropTypes.array.isRequired,
    program: PropTypes.number,
    patient: PropTypes.object.isRequired,
    target: PropTypes.object,
    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    fromLibrary: PropTypes.bool,
    libraries: PropTypes.array,
    isFormDisabled: PropTypes.bool,
    grayedOutFields: PropTypes.array,
    isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    programs: state.data.programs,
    selection: state.general.selection,
    patient: state.patient.patient,
    loading: state.general.loading,
    user: state.auth.user,
    isMobile: state.general.isMobile,
});

export default connect(mapStateToProps, { setLoading, setGlobalDialog, closeGlobalDialog })(NewTarget);
