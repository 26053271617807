import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Wrapper from "./Wrapper";
import { getUnixTime } from "date-fns";
import PhaseColoredButton from "./PhaseColoredButton";

const useStyles = makeStyles((theme) => ({
    promptButton: {
        borderRadius: 0,
        marginRight: 10,
    },
    promptCodes: {
        marginTop: 10,
        display: "flex",
    },
}));

const Prompt = (props) => {
    const classes = useStyles();
    const promptClickHandler = (code) => {
        props.onAddSessionData(
            props.sessionId,
            props.target.id,
            props.target.dataType,
            { code, id: getUnixTime(new Date()) },
            code.code,
        );
    };

    const isResponseAllowed = useMemo(() => {
        const maxTrialNo = props.target.maximumNumberTrials;
        if (!!maxTrialNo && !isNaN(maxTrialNo)) {
            return props.sessionData.length < maxTrialNo;
        }
        return true;
    }, [props.sessionData?.length]);

    return (
        <Wrapper {...props}>
            <div className={classes.promptCodes}>
                {props.target.customData.codes.filter(e => !e.isDeleted).map((code) => (
                    <PhaseColoredButton
                        className={classes.promptButton}
                        onClick={promptClickHandler.bind(undefined, code)}
                        disabled={!isResponseAllowed}
                        phase={props.target?.phase}
                    >
                        {code.code}
                    </PhaseColoredButton>
                ))}
            </div>
        </Wrapper>
    );
};

Prompt.propTypes = {
    sessionData: PropTypes.array.isRequired,
    sessionNotes: PropTypes.array.isRequired,
    sessionId: PropTypes.number.isRequired,
    target: PropTypes.object.isRequired,
    onAddSessionData: PropTypes.func.isRequired,
};

export default Prompt;
