import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
    typography: {
        fontFamily: 'Roboto',
    },
    palette: {
        primary: {
            main: '#0FB99E',
            contrastText: '#fff',
        },
    },
    overrides: {
        MuiInputLabel: {
            asterisk: {
                color: 'red',
            },
            root: {
                fontSize: 14,
                color: '#000000',
                fontWeight: 500,
                marginBottom: 15,
            },
            outlined: {
                color: 'rgba(0, 0, 0, 0.54)',
                fontWeight: 400,
                fontSize: '1rem',
                marginBottom: 0,
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: 14,
            },
        },
        MuiInputBase: {
            root: {
                fontSize: 14,
            },
            multiline: {
                padding: 14,
                alignItems: 'flex-start',
            },
        },
        MuiSelect: {
            root: {
                padding: 14,
            },
            icon: {
                color: '#0FB99E',
                fontSize: 36,
                top: 'calc(50% - 18px)',
            },
        },
        MuiButton: {
            root: {
                fontSize: 14,
                color: '#ffffff',
                fontWeight: 'bold',
                backgroundColor: '#0FB99E',
                borderRadius: 50,
                textTransform: 'none',
                '&:hover': {
                    backgroundColor: '#00826d',
                },
            },
            textPrimary: {
                color: '#ffffff',
                '&:hover': {
                    backgroundColor: '#00826d',
                },
            },
            contained: {
                fontSize: 14,
                color: '#ffffff',
                fontWeight: 'bold',
                backgroundColor: '#0FB99E',
                borderRadius: 5,
                textTransform: 'none',
                '&:hover': { backgroundColor: '#00826d' },
            },
        },
        MuiButtonGroup: {
            grouped: {
                minWidth: 0,
            },
            groupedContainedPrimary: {
                color: '#ffffff',
            },
        },
        MuiFormHelperText: {
            contained: {
                marginLeft: 0,
            },
        },
        MuiDialog: {
            paper: {
                borderRadius: 10,
            },
        },
        MuiPopover: {
            paper: {
                borderRadius: 10,
            },
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: '#F4F4F4',
                padding: '25px 38px 20px 38px',
                fontSize: 22,
            },
        },
        MuiDialogContent: {
            root: {
                padding: '18px 38px 0px 38px',
            },
        },
        MuiDialogActions: {
            root: {
                padding: '20px 38px',
            },
        },
        MuiInputAdornment: {
            root: {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: 14,
            },
        },
        MuiTablePagination: {
            selectIcon: {
                top: 'inherit',
                fontSize: '1.5rem',
            },
        },
        MuiTableCell: {
            root: {
                verticalAlign: 'top',
                fontSize: 14,
            },
        },
        MuiTableHead: {
            root: {
                whiteSpace: 'nowrap',
            },
        },
        MuiChip: {
            root: {
                margin: '3px 5px',
            },
        },
        MuiPickersStaticWrapper: {
            staticWrapperRoot: {
                borderRadius: '10px 10px 0px 0px',
            },
        },
        MuiTable: {
            stickyHeader: {
                borderCollapse: 'collapse',
            },
        },
    },
});

export const phaseColor = {
    intervention: 'lightblue',
    baseline: 'grey',
    generalization: 'orange',
    maintenance: 'green',
};

export const sessionPhaseColor = {
    intervention: '#8abdef',
    baseline: '#F4F4F4',
    generalization: '#e8da7f',
    maintenance: '#87d6cb',
}