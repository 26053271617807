import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Popover, ListItem, List, TextField, Tooltip } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { connect } from 'react-redux';

import { setPatient } from '../store/patient/actions';
import CustomOnboarding from '../assets/onboarding/CustomOnboarding';
import { clientPickerSteps } from '../onboardingSteps';

const useStyles = makeStyles(theme => ({
    patientSelect: {
        backgroundColor: 'white',
        borderRadius: 20,
        paddingRight: 21,
    },
    patientSelectDropdown: {
        fontSize: 18,
        paddingRight: 36,
        paddingBottom: 6,
        paddingTop: 6,
        '&:focus': {
            borderRadius: 20,
            backgroundColor: 'white',
        },
    },
    menuItem: {
        paddingLeft: 32,
        paddingRight: 32,
    },
    noPatient: {
        marginRight: 20,
        color: 'red',
        fontWeight: 'bold',
    },
    patientName: {
        fontSize: 15,
        minWidth: 100,
        margin: 0,
        backgroundColor: 'white',
        padding: '4px 40px 4px 15px',
        borderRadius: 20,
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    arrowDownIcon: {
        right: 2,
        position: 'absolute',
        fontSize: 36,
        color: '#0FB99E',
        top: 'calc(50% - 18px)',
    },
    list: {
        maxHeight: 500,
    },
    searchBar: {
        width: 'calc(100% - 60px)',
        marginLeft: 30,
        marginTop: 10,
    },
    popoverPaper: {
        minWidth: 350,
        maxWidth: 350,
    },
}));

const PatientSelect = (props) => {
    const classes = useStyles();
    const { patients, patient, setPatient } = props;
    const [selectedPatientId, setSelectedPatientId] = useState(undefined);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (patient?.id) {
            setSelectedPatientId(patient.id);
        }
    }, [patient])

    /**
	 * 
	 * @param patient: Refers to selected patient
	 * Resets the domains and grid data
	 */
    const handlePatientSelection = (value) => event => {
        setPatient(patients.find(e => e.id === value));
        setAnchorEl(null);
    }

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setSearchTerm('');
    }

    const patientName = patient?.id ? `${patient.firstName} ${patient.lastName}` : 'Loading...';
    return (
        <React.Fragment>
            {patients.length ?
                <Tooltip title={`${patientName}`}>
                    <p
                        className={classes.patientName}
                        onClick={handleOpen}
                        id="client-picker"
                    >
                        {patientName}
                        <KeyboardArrowDownIcon className={classes.arrowDownIcon} />
                    </p>
                </Tooltip>
                :
                <p className={classes.noPatient}>You have no clients</p>
            }
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{
                    paper: classes.popoverPaper,
                }}
            >
                <TextField
                    placeholder="Search..."
                    className={classes.searchBar}
                    value={searchTerm}
                    label="Search"
                    onChange={(event) => setSearchTerm(event.target.value)}
                    autoFocus
                />
                <List className={classes.list}>
                    {patients?.length && patients
                    .filter(patient => `${patient.firstName} ${patient.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()))
                    .map(patient => (
                        <ListItem
                            className={classes.menuItem}
                            key={patient.id}
                            value={patient.id}
                            button
                            selected={patient.id === selectedPatientId}
                            onClick={handlePatientSelection(patient.id)}
                        >
                            {`${patient.firstName} ${patient.lastName}`}
                        </ListItem>
                    ))}
                </List>
            </Popover>
            {/* Onboarding Disabled */}
            {/* <CustomOnboarding steps={clientPickerSteps} updateTrigger={patients} /> */}
        </React.Fragment>
    )
}

PatientSelect.propTypes = {
    patients: PropTypes.array.isRequired,
    patient: PropTypes.object.isRequired,
    setPatient: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    patients: state.patient.patients,
    patient: state.patient.patient,
});

export default connect(mapStateToProps, { setPatient })(PatientSelect);
