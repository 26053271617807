import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { setLoading } from '../../store/general/actions';
import { startSession } from '../../store/session/actions';
import { createMuiTheme } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { fetchTargetData } from '../../services/sessionSyncService';
import {
    Button,
    Select,
    MenuItem,
    InputLabel,
    Grid,
    FormHelperText,
} from '@material-ui/core';
import dateToIsoString from '../../utils/dateToIsoString';
import { listDataSheet } from '../../services/DataSheetService';
import Session from './Session/Session';

const useStyles = makeStyles((theme) => ({
    NewDataPage: {
        width: 500,
        maxWidth: 'calc(100% - 40px)',
        margin: '30px auto',
        '@media (max-width: 768px)': {
            maxWidth: '90vw',
        }
    },
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
    },
    newDataBtn: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
    },
    input: {
        width: '100%',
    },
    sectionLabel: {
        marginLeft: 'auto',
        marginRight: 'auto',
        fontWeight: 'bold',
    },
    dateSheetLabel: {
        marginTop: 20,
    },
    dialogPaper: {
        width: 370,
        maxWidth: 'calc(100vw - 64px)',
    },
    dialogContainer: {
        maxWidth: '100vw',
    },
    dataSheetInput: {
        height: 120,
        alignItems: 'baseline',
    },
}));

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                display: 'none',
            },
        }
    },
});

const NewDataPage = (props) => {
    const { patient, loading } = props;

    const classes = useStyles();
    const [date, setDate] = useState(new Date());
    const [dataSheet, setDataSheet] = useState(0);
    const [showError, setShowError] = useState(false);
    const [dataSheets, setDataSheets] = useState([]);

    useEffect(() => {
        if (patient?.id) {
            listDataSheet(patient.id).then((res) => {
                setDataSheets(res.data.data);
            })
        }
    }, [patient]);

    const startSession = async () => {
        if (!date || (dataSheets?.length && !dataSheet)) {
            setShowError(true);
            return;
        }
        let targetData = await fetchTargetData(patient.id, dateToIsoString(date), dataSheet);
        props.startSession(patient.id, dataSheet, date.toJSON(), targetData);
    }

    return (

        <div className={classes.NewDataPage}>
            <Grid container xs={12}> <InputLabel className={classes.sectionLabel} required>Date</InputLabel></Grid>
            <Grid container spacing={2} justify='center' alignContent='stretch'>
                <ThemeProvider theme={materialTheme}>
                    <KeyboardDatePicker
                        autoOk
                        variant="static"
                        openTo="date"
                        value={date}
                        onChange={setDate}
                        maxDate={new Date()}
                    />
                </ThemeProvider>
            </Grid>
            {dataSheets?.length > 0 && <Grid container className={classes.dataSheetInput} xs={12}>
                <InputLabel id="new-data-datasheet-field-label" className={`${classes.sectionLabel} ${classes.dateSheetLabel}`} required>Data Sheet</InputLabel>
                <Select
                    id="new-data-datasheet-field"
                    variant='outlined'
                    IconComponent={KeyboardArrowDownIcon}
                    className={classes.input}
                    error={showError && !dataSheet}
                    value={dataSheet}
                    onChange={(event) => { setDataSheet(event.target.value) }}
                >
                    <MenuItem disabled value={0}>
                        Choose
                    </MenuItem>
                    <MenuItem value={-1}>
                        All Active Targets
                    </MenuItem>
                    {dataSheets.map((sheet) => (
                        <MenuItem key={sheet.id} value={sheet.id}>
                            {sheet.name}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText id="new-data-datasheet-field-helper-text" error={showError && !dataSheet}>
                    {showError && !dataSheet
                        ? 'Please choose a data sheet'
                        : ' '}
                </FormHelperText>
            </Grid>}
            <Grid item xs={12} container justify='flex-end'>
                <Button
                    id="new-data-start-session"
                    className={classes.newDataBtn}
                    disabled={loading.newDataForm}
                    onClick={startSession}
                    variant="contained"
                >
                    Start Session
                </Button>
            </Grid>

            <Session onSessionEnd={() => {}} />
        </div>
    );
}

const mapStateToProps = (state) => ({
    programs: state.data.programs,
    selection: state.general.selection,
    patient: state.patient.patient,
    loading: state.general.loading,
    user: state.auth.user,
});

NewDataPage.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onSessionStart: PropTypes.func.isRequired,
    selection: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    dataSheets: PropTypes.array.isRequired,
    startSession: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setLoading, startSession })(NewDataPage);