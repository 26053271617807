import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Popover from '@material-ui/core/Popover';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import { connect } from 'react-redux';
import debounce from 'awesome-debounce-promise';

import { getUsers, downloadUsers, uploadUsers } from '../../services/UserService';
import { setLoading, setSnackbar, setUploadDialog } from '../../store/general/actions';
import UserTable from './UserTable';
import NewUser from './NewUser';
import { postUser, updateUser } from '../../services/UserService';
import CanUser from '../CanUser';
import urlDownload from '../../utils/urlDownload';
import { access, accountTypes, localStorageEnum } from '../../constants';
import { getRolesDropdown } from '../../services/RoleService';
import TooltipedIconButton from '../../assets/button/TooltipedIconButton';
import { getSitesDropdown } from '../../services/SiteService';
import MobileSearchInput from '../../assets/input/MobileSearchInput';

const useStyles = makeStyles(theme => ({
    root: {
        // padding: '10px 0 10px 40px',
        backgroundColor: '#FFFFFF'
    },
    heading: {
        display: 'flex',
        maxWidth: 'calc(100vw - 50px)',
        margin: 'auto',
    },
    plus: {
        width: 14,
        marginLeft: 5,
    },
    btnWrapper: {
        padding: '5px 10px 15px 0px',
        flexWrap: 'nowrap',
    },
    searchField: {
        width: '100%',
    },
    popoverPaper: {
        padding: 20,
    },
    menuItem: {
        textTransform: 'capitalize',
    },
    upDownButton: {
        marginLeft: 10,
    },
    userTable: {
        maxWidth: 'calc(100vw - 50px)',
        margin: 'auto',
        // paddingRight: 40
    }
}));

const statuses = ['all', 'active', 'inactive'];
const debouncedGetUsers = debounce(getUsers, 500);

const User = (props) => {
    const classes = useStyles();
    const { setLoading, setSnackbar, user, setUploadDialog, loading, isMobile } = props;
    const [users, setUsers] = useState([]);
    const isMounted = useRef(false);

    const [status, setStatus] = useState(statuses[1]);
    const [accountType, setAccountType] = useState(accountTypes[1].name);
    const [sites, setSites] = useState([]);
    const [roles, setRoles] = useState([]);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState({ sortAs: '', sortBy: '' });
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(0);

    const [anchorEl, setAnchorEl] = React.useState({
        sites: null,
        status: null,
        accountType: null,
    });
    const [selectedUser, setSelectedUser] = useState(null);
    const [dialogOpen, setDialogOpen] = useState({
        newUser: false,
    });

    useEffect(() => {
        let userFilters = localStorage.getItem(localStorageEnum.userFilters);
        let parsedSites = [];
        let parsedRoles = [];

        if (userFilters) {
            userFilters = JSON.parse(userFilters);
            setStatus(userFilters.status);
            setSort(userFilters.sort);
            setAccountType(userFilters.accountType);
            parsedSites = userFilters.sites;
            parsedRoles = userFilters.roles;
        }

        getSitesDropdown().then((res) => {
            const newSites = [];
            const shouldCheckAll = !parsedSites.length || !parsedSites.some(e => e.checked) || !parsedSites.find(e => !e.checked);
            res.data.data.forEach(site => {
                newSites.push({ ...site, checked: shouldCheckAll || parsedSites.find(e => e.id === site.id)?.checked })
            });
            setSites(newSites);
        });

        getRolesDropdown().then((res) => {
            const fetchedRoles = res.data.data.roles;
            const newRoles = [];
            const shouldCheckAllRoles = !parsedRoles.length || !parsedRoles.some(e => e.checked) || !parsedRoles.find(e => !e.checked);
            fetchedRoles.forEach(role => {
                newRoles.push({ ...role, checked: shouldCheckAllRoles || parsedRoles.find(e => e.id === role.id)?.checked })
            });
            setRoles(newRoles);

            isMounted.current = true;
        });

        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        const userFilters = { status, sites, sort, roles, accountType };
        localStorage.setItem(localStorageEnum.userFilters, JSON.stringify(userFilters));
    }, [status, sites, sort, roles, accountType])

    useEffect(() => {
        fetchUser();
    }, [sort, status, search, sites, roles, accountType])

    const fetchUser = () => {
        setLoading('getUsers');
        const sitesFilter = sites.some(e => e.checked)
            ? sites.filter(e => e.checked).map(site => site.id)
            : sites.map(site => site.id);
        const rolesFilter = roles.some(e => e.checked)
            ? roles.filter(e => e.checked).map(role => role.id)
            : roles.map(role => role.id);
        debouncedGetUsers(
            sort.sortBy,
            sort.sortAs,
            skip,
            limit,
            status === 'active' ? true : status === 'inactive' ? false : undefined,
            sitesFilter,
            rolesFilter,
            search,
            accountType,
        ).then((res) => {
            if (isMounted.current) {
                setUsers(res.data.data.users);
                setLoading('getUsers', false);
            }
        });
    }

    const openAnchorEl = (type, el) => {
        setAnchorEl({ ...anchorEl, [type]: el });
    }

    const closeAnchorEl = type => {
        setAnchorEl({ ...anchorEl, [type]: null });
    }

    const openDialog = type => () => {
        setDialogOpen({ ...dialogOpen, [type]: true });
    }

    const closeDialog = type => () => {
        setDialogOpen({ ...dialogOpen, [type]: false });
    }

    const handleChange = id => event => {
        const newSites = sites;
        const changedSite = newSites.find(e => e.id === id);
        changedSite.checked = !changedSite.checked;

        setSites(newSites.slice());
    };

    const handleChangeRoles = id => event => {
        const newRoles = roles;
        const changedRole = newRoles.find(e => e.id === id);
        changedRole.checked = !changedRole.checked;

        setRoles(newRoles.slice());
    };

    const handleChangeStatus = (e) => () => {
        setStatus(e);
        closeAnchorEl('status');
    }

    const handleChangeAccountType = (e) => () => {
        setAccountType(e);
        closeAnchorEl('accountType');
    }

    const handleSearch = event => {
        setSearch(event.target.value);
    }

    const handleCheckAll = () => {
        if (isAllChecked()) {
            sites.forEach(site => {
                site.checked = false;
            });
        } else {
            sites.forEach(site => {
                site.checked = true;
            });
        }
        setSites(sites.slice());
    }

    const isAllChecked = () => {
        return !sites.some(site => !site.checked);
    }

    const handleCheckAllRoles = () => {
        if (isAllCheckedRoles()) {
            roles.forEach(role => {
                role.checked = false;
            });
        } else {
            roles.forEach(role => {
                role.checked = true;
            });
        }
        setRoles(roles.slice());
    }

    const isAllCheckedRoles = () => {
        return !roles.some(role => !role.checked);
    }

    const handleSave = (data) => {
        if (selectedUser) { // edit
            setLoading('editUser');
            updateUser(data).then(() => {
                setLoading('editUser', false);
                setSnackbar('success', 'User updated!');
                fetchUser();
                handleCloseDialog();
            }).catch((err) => {
                setLoading('editUser', false);
            });
        } else {
            setLoading('newUser');
            postUser({ ...data, accountType: 'employee' }).then(() => {
                setLoading('newUser', false);
                setSnackbar('success', 'User added!');
                fetchUser();
                handleCloseDialog();
            }).catch((err) => {
                setLoading('newUser', false);
            });
        }
    }

    const handleCloseDialog = () => {
        setSelectedUser(null);
        closeDialog('newUser')();
    }

    const handleEdit = (user) => {
        setSelectedUser(user);
        openDialog('newUser')();
    }

    const isFilterActive = (type) => {
        let isActive = false;
        switch (type) {
            case 'status':
                if (status !== 'all') isActive = true;
                break;

            case 'sites':
                if (!isAllChecked() && sites.some(e => e.checked)) isActive = true;
                break;

            case 'accountType':
                if (accountType !== 'all') isActive = true;
                break;

            case 'roles':
                if (!isAllCheckedRoles() && roles.some(e => e.checked)) isActive = true;
                break;

            default:
                break;
        }

        return isActive;
    }

    const handleUpload = () => {
        setUploadDialog(
            'Upload users',
            'Import user data using the provided template.',
            require("../../resources/templates/User_Import_Template.xlsx"),
            'User_Import_Template.xlsx',
            ['.xlsx'],
            uploadUsers,
            fetchUser,
        );
    }

    const handleDownload = () => {
        setLoading('downloadUsers');
        const sitesFilter = sites.some(e => e.checked)
            ? sites.filter(e => e.checked).map(site => site.id)
            : sites.map(site => site.id);
        const rolesFilter = roles.some(e => e.checked)
            ? roles.filter(e => e.checked).map(role => role.id)
            : roles.map(role => role.id);
        downloadUsers(
            sort.sortBy,
            sort.sortAs,
            skip,
            limit,
            status === 'active' ? true : status === 'inactive' ? false : undefined,
            sitesFilter,
            rolesFilter,
            search,
            accountType
        ).then((res) => {
            urlDownload(res.data.data.path);
            setLoading('downloadUsers', false);
        });
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.heading}>
                <Grid item xs={isMobile ? 6 : 3} className={classes.btnWrapper}>
                    <CanUser
                        perform={access.users.add}
                        yes={() => (
                            <Button id="user-add" onClick={openDialog('newUser')} variant="contained">New User <img className={classes.plus} src={require('../../resources/PlusIcon.svg')} alt=''/></Button>
                        )}
                    />
                </Grid>
                {!isMobile && <Grid item xs={6} className={classes.statusWrapper} id="user-search">
                    <TextField
                        id="user-search-filed"
                        autoComplete="search-field"
                        onChange={handleSearch}
                        value={search}
                        variant="outlined"
                        className={classes.searchField}
                        placeholder="First Name, Last Name, Username, Email, Site, Role"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>}
                <Grid item xs={isMobile ? 6 : 3} className={classes.btnWrapper} container justify="flex-end">
                    {isMobile && <MobileSearchInput onSearch={handleSearch} />}
                    <CanUser
                        perform={access.users.uploadDownload}
                        yes={() => (
                            <React.Fragment>
                                <TooltipedIconButton
                                    id="user-download"
                                    icon={<PublishIcon />}
                                    onClick={handleUpload}
                                    tooltip="Upload"
                                    className={classes.upDownButton}
                                />
                                <TooltipedIconButton
                                    id="user-upload"
                                    icon={<GetAppIcon />}
                                    onClick={handleDownload}
                                    disabled={loading.downloadUsers || !users.length}
                                    tooltip="Download"
                                    className={classes.upDownButton}
                                />
                            </React.Fragment>
                        )}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.userTable}>
                <UserTable
                    setSkip={setSkip}
                    setSort={setSort}
                    setLimit={setLimit}
                    fetchUser={fetchUser}
                    users={users}
                    onEdit={handleEdit}
                    openFilter={openAnchorEl}
                    isFilterActive={isFilterActive}
                    sort={sort}
                />
            </Grid>

            <NewUser
                open={dialogOpen.newUser}
                onClose={handleCloseDialog}
                onSave={handleSave}
                selectedUser={selectedUser}
                fetchUser={fetchUser}
            />
            <Popover
                open={!!anchorEl.sites}
                anchorEl={anchorEl.sites}
                onClose={() => closeAnchorEl('sites')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{
                    paper: classes.popoverPaper,
                }}
            >
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={isAllChecked()} onChange={handleCheckAll} value="all" />}
                        label="All"
                    />
                    {sites.map(site => (
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={site.checked} onChange={handleChange(site.id)} value="site" />}
                            label={site.name}
                            key={site.id}
                        />
                    ))}
                </FormGroup>
            </Popover>

            <Popover
                open={!!anchorEl.roles}
                anchorEl={anchorEl.roles}
                onClose={() => closeAnchorEl('roles')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{
                    paper: classes.popoverPaper,
                }}
            >
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={isAllCheckedRoles()} onChange={handleCheckAllRoles} value="all" />}
                        label="All"
                    />
                    {roles.map(role => (
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={role.checked} onChange={handleChangeRoles(role.id)} value="role" />}
                            label={role.name}
                            key={role.id}
                        />
                    ))}
                </FormGroup>
            </Popover>

            <Menu
                id="status-menu"
                anchorEl={anchorEl.status}
                keepMounted
                open={Boolean(anchorEl.status)}
                onClose={() => closeAnchorEl('status')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
            >
                {statuses.map(e => (
                    <MenuItem
                        id={`status-menu-${e}`}
                        key={e}
                        className={classes.menuItem}
                        selected={status === e}
                        onClick={handleChangeStatus(e)}
                    >
                        {e}
                    </MenuItem>
                ))}
            </Menu>

            <Menu
                id="accountType-menu"
                anchorEl={anchorEl.accountType}
                keepMounted
                open={Boolean(anchorEl.accountType)}
                onClose={() => closeAnchorEl('accountType')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
            >
                {accountTypes.map(e => (
                    <MenuItem
                        id={`accountType-menu-${e.name}`}
                        key={e.name}
                        className={classes.menuItem}
                        selected={accountType === e.name}
                        onClick={handleChangeAccountType(e.name)}
                    >
                        {e.label}
                    </MenuItem>
                ))}
            </Menu>
            {/* Onboarding Disabled */}
            {/* <CustomOnboarding steps={userSteps} /> */}
        </Grid>
    )
}

User.propTypes = {
    setSnackbar: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setUploadDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    loading: state.general.loading,
    user: state.auth.user,
    isMobile: state.general.isMobile
})

export default connect(mapStateToProps, { setSnackbar, setLoading, setUploadDialog })(User);