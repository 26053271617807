import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid,
    CircularProgress,
    makeStyles,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { connect } from 'react-redux';
import format from 'date-fns/format';

import { CanUserFunc } from '../CanUser';
import LoadingCheck from '../../assets/progress/LoadingCheck';
import { dataTypeDict, access } from '../../constants';
import { defaultColumnSettings } from './../UserSetting/DisplayColumns';
import clsx from 'clsx';

import TableSortLabel from '@material-ui/core/TableSortLabel';

const dataType = { ...dataTypeDict, correctIncorrect: '+/-' };

const useStyles = makeStyles((theme) => ({
    tableHeadRow: {
        fontWeight: 500,
        fontSize: 16,
        '& th': {
            verticalAlign: 'middle',
        },
        backgroundColor: '#FFFFFF',
    },
    underline: {
        textTransform: 'capitalize',
        cursor: 'pointer',
        paddingBottom: 3,
        textDecoration: 'none',
        borderBottom: '1px solid black',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    table: {
        position: 'relative',
    },
    tableCell: {
        fontSize: 14,
        border: 'none',
        padding: '15px 12px',
        verticalAlign: 'middle',
    },
    tableCellStar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: 'none',
    },
    tableCellAction: {
        fontSize: 14,
        border: 'none',
        padding: '0px 12px',
        verticalAlign: 'middle',
    },
    progressWrapper: {
        position: 'absolute',
        top: 56,
        paddingTop: 150,
        height: '100%',
        backgroundColor: '#fff',
    },
    noTarget: {
        textAlign: 'center',
        marginTop: '30vh',
        fontSize: 36,
    },
    activeFilter: {
        color: '#0FB99E',
    },
    iconButton: {
        marginLeft: 5,
        padding: 4,
        position: 'absolute',
        top: 13,
    },
    starredStarIcon: {
        color: '#ffb52b',
    },
    defaultRowBackground: {
        backgroundColor: '#FFFFFF',

        '&:hover': {
            backgroundColor: '#F0F0F0',
        },
    },
    intervention: {
        backgroundColor: 'rgba(19, 137, 222, 0.15)',

        '&:hover': {
            backgroundColor: 'rgba(19, 137, 222, 0.3)',
        },
    },
    baseline: {
        backgroundColor: '#F4F4F4',

        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    generalization: {
        backgroundColor: 'rgba(222, 202, 19, 0.15)',

        '&:hover': {
            backgroundColor: 'rgba(222, 202, 19, 0.3)',
        },
    },
    maintenance: {
        backgroundColor: 'rgba(15, 185, 158, 0.15)',

        '&:hover': {
            backgroundColor: 'rgba(15, 185, 158, 0.3)',
        },
    },
    checkbox: {
        padding: 0,
    },
    star: {
        padding: 0,
    },
    pendingReview: {
        color: 'red',
    },
}));

const TargetGrid = (props) => {
    const {
        targets,
        targetLoading,
        selectedId,
        loading,
        openMenu,
        openCalendar,
        handleStar,
        onSort,
        sort,
        handleRowEdit,
        isStatusFilterActive,
        isStarFilterActive,
        user,
        isMobile
    } = props;

    const classes = useStyles();

    const [columnsSetting, setColumnsSetting] = useState({});
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    useEffect(() => {
        const clinicalColumnsSetting = user.settings?.columnsSetting?.clinicals;
        const formattedSetting = {};
        if (clinicalColumnsSetting) {
            Object.entries(clinicalColumnsSetting).forEach(([key, value]) => {
                formattedSetting[key] = value;
            });
        } else {
            defaultColumnSettings.clinicals.forEach((item) => {
                formattedSetting[item.key] = item.checked;
            });
        }
        setColumnsSetting(formattedSetting);
    }, [user]);

    useEffect(() => {
        if (sort) {
            setSortOrder(sort.sortAs || 'asc');
            setSortField(sort.sortBy || '');
        }
    }, [sort])

    const showActions =
        CanUserFunc(access.targets.edit) ||
        CanUserFunc(access.targets.remove) ||
        CanUserFunc(access.targets.add);

    const canEdit = CanUserFunc(access.targets.edit);

    const sortHandler = (newSortField) => {
        if (sortField === newSortField && sortField !== '') {
            onSort(sortField, sortOrder === 'asc' ? 'desc' : 'asc');
        } else if (sortField !== newSortField) {
            onSort(newSortField, 'asc');
        }
    }

    return (
        <div style={{ width: '100%', overflowX: 'auto', position: 'relative' }}>
            <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                        {columnsSetting.star && (
                            <TableCell
                                align='center'
                                style={{ paddingRight: 24, position: 'relative' }}
                                id='target-grid-star'
                            >
                                Star
                                <IconButton
                                    className={classes.iconButton}
                                    onClick={openMenu(0, 'starFilter')}
                                >
                                    <KeyboardArrowDownIcon
                                        className={isStarFilterActive ? classes.activeFilter : ''}
                                    />
                                </IconButton>
                            </TableCell>
                        )}
                        <TableCell align='center'>
                            <TableSortLabel
                                direction={sortField === 'program_name' ? sortOrder : 'asc'}
                                active={sortField === 'program_name'}
                                onClick={sortHandler.bind(undefined, 'program_name')}
                            >Program</TableSortLabel>
                        </TableCell>
                        <TableCell align='center'>
                            <TableSortLabel
                                direction={sortField === 'name' ? sortOrder : 'asc'}
                                active={sortField === 'name'}
                                onClick={sortHandler.bind(undefined, 'name')}
                            >Target</TableSortLabel>
                        </TableCell>
                        {columnsSetting.status && (
                            <TableCell
                                align='center'
                                style={{ paddingRight: 24, position: 'relative' }}
                            >
                                Status
                                <IconButton
                                    className={classes.iconButton}
                                    onClick={openMenu(0, 'statusFilter')}
                                >
                                    <KeyboardArrowDownIcon
                                        className={isStatusFilterActive ? classes.activeFilter : ''}
                                    />
                                </IconButton>
                            </TableCell>
                        )}
                        {columnsSetting.phase && <TableCell align='center'>
                            <TableSortLabel
                                direction={'asc'}
                                active={sortField === '' || sortField === null}
                                onClick={sortHandler.bind(undefined, '')}
                            >Phase</TableSortLabel>
                        </TableCell>}
                        {columnsSetting.dataType && <TableCell align='center'>
                            <TableSortLabel
                                direction={sortField === 'dataType' ? sortOrder : 'asc'}
                                active={sortField === 'dataType'}
                                onClick={sortHandler.bind(undefined, 'dataType')}
                            >Data</TableSortLabel>
                        </TableCell>}
                        {columnsSetting.opened && <TableCell align='center'>
                            <TableSortLabel
                                direction={sortField === 'openedAt' ? sortOrder : 'asc'}
                                active={sortField === 'openedAt'}
                                onClick={sortHandler.bind(undefined, 'openedAt')}
                            >Opened</TableSortLabel>
                        </TableCell>}
                        {columnsSetting.mastered && <TableCell align='center'>
                            <TableSortLabel
                                direction={sortField === 'masteredAt' ? sortOrder : 'asc'}
                                active={sortField === 'masteredAt'}
                                onClick={sortHandler.bind(undefined, 'masteredAt')}
                            >Mastered</TableSortLabel>
                        </TableCell>}
                        {showActions && (
                            <TableCell align='center' id='target-grid-action'>
                                Action
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {targets &&
                        targets.map((target) => (
                            <TableRow
                                key={target.id}
                                className={
                                    target.status === 'inactive' || target.status === 'mastered'
                                        ? classes.defaultRowBackground
                                        : classes[target.phase.toLowerCase()]
                                }
                                onClick={canEdit ? handleRowEdit(target) : null}
                            >
                                {columnsSetting.star && (
                                    <TableCell className={classes.tableCell} align='center'>
                                        {!target.pendingReview && (
                                            <IconButton
                                                className={classes.star}
                                                onClick={
                                                    canEdit
                                                        ? handleStar(target.id, !target.isStarred)
                                                        : null
                                                }
                                            >
                                                {target.isStarred ? (
                                                    <StarIcon id={`program-table-cell-star-active-${target.id}`} className={classes.starredStarIcon} />
                                                ) : (
                                                    <StarBorderIcon id={`program-table-cell-star-innactive-${target.id}`} />
                                                )}
                                            </IconButton>
                                        )}
                                    </TableCell>
                                )}
                                <TableCell id={`program-table-cell-program-name-${target.id}`} className={classes.tableCell} align='center'>
                                    {target.program.name}
                                </TableCell>
                                <TableCell id={`program-table-cell-target-name-${target.id}`} className={classes.tableCell} align='center'>
                                    {target.name}
                                </TableCell>
                                {columnsSetting.status && (
                                    <TableCell id={`program-table-cell-status-${target.id}`} className={classes.tableCell} align='center'>
                                        {canEdit && !target.pendingReview ? (
                                            <u
                                                onClick={openMenu(target.id, 'status')}
                                                className={classes.underline}
                                            >
                                                {target.status}
                                            </u>
                                        ) : (
                                            <span
                                                className={clsx(
                                                    classes.capitalize,
                                                    target.pendingReview && classes.pendingReview
                                                )}
                                            >
                                                {target.pendingReview
                                                    ? 'Incomplete Data'
                                                    : target.status}
                                            </span>
                                        )}
                                        <LoadingCheck
                                            show={target.id === selectedId && loading.editStatus}
                                            size={12}
                                        />
                                    </TableCell>
                                )}
                                {columnsSetting.phase && (
                                    <TableCell id={`program-table-cell-phase-${target.id}`} className={classes.tableCell} align='center'>
                                        {canEdit ? (
                                            target.status !== 'inactive' &&
                                                target.status !== 'mastered' &&
                                                target.phase ? (
                                                <u
                                                    onClick={openMenu(target.id, 'phase')}
                                                    className={classes.underline}
                                                >
                                                    {target.phase}
                                                </u>
                                            ) : (
                                                '- - -'
                                            )
                                        ) : (
                                            <span className={classes.capitalize}>
                                                {target.status === 'inactive' ||
                                                    target.status === 'mastered'
                                                    ? '- - -'
                                                    : target.phase || '- - -'}
                                            </span>
                                        )}
                                        <LoadingCheck
                                            show={target.id === selectedId && loading.editPhase}
                                            size={12}
                                        />
                                    </TableCell>
                                )}
                                {columnsSetting.dataType && (
                                    <TableCell id={`program-table-cell-data-${target.id}`} className={classes.tableCell} align='center'>
                                        {dataType[target.dataType]}
                                    </TableCell>
                                )}
                                {columnsSetting.opened && (
                                    <TableCell id={`program-table-cell-opened-${target.id}`} className={classes.tableCell} align='center'>
                                        {canEdit ? (
                                            target.openedAt ? (
                                                <u
                                                    onClick={openCalendar(
                                                        target.id,
                                                        'openedAt',
                                                        new Date(target.openedAt)
                                                    )}
                                                    className={classes.underline}
                                                >
                                                    {format(new Date(target.openedAt), 'M/d/yy')}
                                                </u>
                                            ) : (
                                                '- - -'
                                            )
                                        ) : (
                                            <span className={classes.capitalize}>
                                                {target.openedAt
                                                    ? format(new Date(target.openedAt), 'M/d/yy')
                                                    : '- - -'}
                                            </span>
                                        )}
                                        <LoadingCheck
                                            show={target.id === selectedId && loading.editOpened}
                                            size={12}
                                        />
                                    </TableCell>
                                )}
                                {columnsSetting.mastered && (
                                    <TableCell id={`program-table-cell-mastered-${target.id}`} className={classes.tableCell} align='center'>
                                        {canEdit ? (
                                            target.masteredAt ? (
                                                <u
                                                    onClick={openCalendar(
                                                        target.id,
                                                        'masteredAt',
                                                        new Date(target.masteredAt)
                                                    )}
                                                    className={classes.underline}
                                                >
                                                    {format(new Date(target.masteredAt), 'M/d/yy')}
                                                </u>
                                            ) : (
                                                '- - -'
                                            )
                                        ) : (
                                            <span className={classes.capitalize}>
                                                {target.masteredAt
                                                    ? format(new Date(target.masteredAt), 'M/d/yy')
                                                    : '- - -'}
                                            </span>
                                        )}
                                        <LoadingCheck
                                            show={target.id === selectedId && loading.editMastered}
                                            size={13}
                                        />
                                    </TableCell>
                                )}
                                {showActions && (
                                    <TableCell id={`program-table-cell-action-${target.id}`} className={classes.tableCellAction} align='center'>
                                        <u
                                            onClick={openMenu(target.id, 'action')}
                                            className={classes.underline}
                                        >
                                            Action
                                        </u>
                                        <LoadingCheck
                                            show={
                                                target.id === selectedId &&
                                                (loading.deleteTarget ||
                                                    loading.archive ||
                                                    loading.unarchive ||
                                                    target.duplicate)
                                            }
                                            size={12}
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            {targetLoading && (
                <Grid className={classes.progressWrapper} container justify='center'>
                    <CircularProgress size={300} />
                </Grid>
            )}
            {!targetLoading && props.patient?.id && !targets.length && (
                <p className={classes.noTarget}>No target to show</p>
            )}
            {/* Onboarding Disabled */}
            {/* <CustomOnboarding steps={targetGridSteps} /> */}
        </div>
    );
};

TargetGrid.defaultProps = {
    openMenu: () => { },
    openCalendar: () => { },
    handleStar: () => { },
    handleRowEdit: () => { },
};

TargetGrid.propTypes = {
    targets: PropTypes.array.isRequired,
    targetLoading: PropTypes.bool.isRequired,
    selectedId: PropTypes.number,
    loading: PropTypes.object.isRequired,
    openMenu: PropTypes.func,
    openCalendar: PropTypes.func,
    handleRowEdit: PropTypes.func,
    handleStar: PropTypes.func,
    onSort: PropTypes.func,
    sort: PropTypes.object,
    isStatusFilterActive: PropTypes.bool.isRequired,
    isStarFilterActive: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    domains: state.data.domains,
    programs: state.data.programs,
    selection: state.general.selection,
    patient: state.patient.patient,
    loading: state.general.loading,
    targetLoading: state.general.targetLoading,
    user: state.auth.user,
    isMobile: state.general.isMobile,
});

export default connect(mapStateToProps, {})(TargetGrid);
