import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button, DialogTitle, DialogContent, DialogActions, Dialog, Checkbox,
    InputLabel, TextField, Grid, IconButton, FormGroup, FormControlLabel,
    TableCell, TableHead, Table, TableRow, TableBody,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddBox';
import ViewIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Create';
import RemoveIcon from '@material-ui/icons/Delete';
import UploadDownloadIcon from '@material-ui/icons/ImportExport';
import BlockIcon from '@material-ui/icons/Block';
import FullAccessIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import { permissionGroups, permissionActions, additionalPermissions } from '../../constants';

const useStyles = makeStyles(theme => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
    },
    leftWrapper: {
        paddingRight: 19,
        marginBottom: 28,
    },
    rightWrapper: {
        paddingLeft: 19,
        marginBottom: 28,
    },
    largeWrapper: {
        marginBottom: 28,
    },
    input: {
        width: '95%',
    },
    multilineInput: {
        minHeight: 90,
    },
    multilineInputLarge: {
        minHeight: 225,
    },
    saveBtn: {
        padding: '6px 40px',
    },
    btnBlockAll: {
        padding: '2px 8px',
        backgroundColor: '#bdbdbd',
        fontSize: 11,
        '&:hover': { backgroundColor: 'red' }
    },
    btnBlockAllActive: {
        padding: '2px 8px',
        backgroundColor: 'red',
        fontSize: 11,
        '&:hover': { backgroundColor: 'red' }
    },
    btnFullAccess: {
        padding: '2px 8px',
        backgroundColor: '#bdbdbd',
        fontSize: 11,
        '&:hover': { backgroundColor: '#d6cf47' }
    },
    btnFullAccessActive: {
        padding: '2px 8px',
        backgroundColor: '#d6cf47',
        fontSize: 11,
        '&:hover': { backgroundColor: '#d6cf47' }
    },
    btnDefault: {
        padding: '2px 8px',
        backgroundColor: '#bdbdbd',
        fontSize: 11,
        '&:hover': { backgroundColor: '#00826d' }
    },
    btnActive: {
        padding: '2px 8px',
        backgroundColor: '#0FB99E',
        fontSize: 11,
        '&:hover': { backgroundColor: '#0FB99E' }
    },
    selectAll: {
        '&:hover': { color: '#00826d' },
        cursor: 'pointer',
        padding: '5px',
        verticalAlign: 'middle',
        lineHeight: 1,
    },
    selectAllActive: {
        color: '#0FB99E',
        cursor: 'pointer',
        padding: '5px',
        verticalAlign: 'middle',
        lineHeight: 1,
    },
    blockedAll: {
        '&:hover': { color: 'red' },
        cursor: 'pointer',
        padding: '5px',
        verticalAlign: 'middle',
        lineHeight: 1,
    },
    blockedAllActive: {
        color: 'red',
        cursor: 'pointer',
        padding: '5px',
        verticalAlign: 'middle',
        lineHeight: 1,
    },
    fullAccess: {
        '&:hover': { color: '#d6cf47' },
        cursor: 'pointer',
        padding: '5px',
        verticalAlign: 'middle',
        lineHeight: 1,
    },
    fullAccessActive: {
        color: '#d6cf47',
        cursor: 'pointer',
        padding: '5px',
        verticalAlign: 'middle',
        lineHeight: 1,
    },
    tblHeadRow: {
        fontWeight: 'bold',
        backgroundColor: '#f8f8f8',
    },
    rowHeader: {
        verticalAlign: 'middle',
        fontWeight: 500,
        fontSize: 12,
        padding: '5px',
    },
    rowHeader2: {
        verticalAlign: 'middle',
        fontWeight: 500,
        fontSize: 12,
        padding: '5px',
        paddingLeft: '15px',
        fontWeight: 'normal',
    },
    table: {
        height: 420,
    },
    tCell: {
        padding: '5px',
        verticalAlign: 'middle',
    },
    tCellWarning: {
        padding: '5px',
        verticalAlign: 'middle',
        fontSize: 12,
        color: 'red',
        borderBottom: 'none',
    },
    errorText: {
        fontSize: 12,
        color: 'red',
    },
    groupRow: {
        backgroundColor: 'rgba(15, 185, 158, 0.3)',
    },
    smallAll: {
        fontSize: 10,
        display: 'block',
    },
    label: {
        fontSize: 12,
    },
}));

const adminRoles = ['users', 'roles', 'clients', 'sites', 'devices'];
const adminActionsFilter = [];
const clinicalsRoles = ['targets', 'programs', 'analysis', 'domains', 'data', 'notes'];

const defaultRole = {
    name: '',
    permissions: {},
    editable: true,
}

permissionGroups.forEach((group) => {
    defaultRole.permissions[group.name] = {};
    permissionActions.forEach((action) => {
        defaultRole.permissions[group.name][action.name] = false;
    })
})

additionalPermissions.forEach((permission) => {
    defaultRole[permission.name] = !!permission.isReverse;
})

const NewRole = (props) => {
    const classes = useStyles();
    const { onClose, open, isEdit, role, onSubmit, loading, roles } = props;
    const [newRole, setNewRole] = useState(cloneDeep(defaultRole));
    const [error, setError] = useState(false);

    useEffect(() => {
        if (open) {
            if (isEdit) {
                setNewRole({ ...cloneDeep(role), permissions: { ...cloneDeep(defaultRole.permissions), ...cloneDeep(role.permissions) } });
            } else {
                setNewRole(cloneDeep(defaultRole));
            }
            setError(false);
        }
    }, [open])

    const handleCancel = () => {
        onClose();
    };

    const handleChange = field => event => {
        setNewRole({ ...newRole, [field]: event.target.value });
    }

    const handleCheckAdditional = field => event => {
        setNewRole({ ...newRole, [field]: !newRole[field] });
    };

    const handleClickCell = (rowName, cellName) => () => {
        if (cellName === 'blockAll' && !checkIsRow(rowName, false)) {
            setRow(rowName, false);
        } else if (cellName === 'blockAll') {
            setRow(rowName, true);
        }

        if (cellName === 'fullAccess' && !checkIsRow(rowName, true)) {
            setRow(rowName, true);
        } else if (cellName === 'fullAccess') {
            setRow(rowName, false);
        }

        if (cellName !== 'blockAll' && cellName !== 'fullAccess') {
            if (checkIsCell(rowName, cellName, true)) {
                setCell(rowName, cellName, false);
            } else {
                setCell(rowName, cellName, true);
            }
        }
    }

    const setCell = (rowName, cellName, isActive) => {
        const permissions = newRole.permissions;
        if (!permissions[rowName]) {
            permissions[rowName] = {};
        }
        permissions[rowName][cellName] = isActive;

        setNewRole({ ...newRole, permissions });
    }

    const checkIsCell = (rowName, cellName, isActive) => {
        return newRole.permissions[rowName] && newRole.permissions[rowName][cellName] === isActive;
    }

    const setRow = (rowName, isActive) => {
        const permissions = newRole.permissions;
        permissionActions.forEach((action) => {
            if (!permissions[rowName]) {
                permissions[rowName] = {};
            }
            permissions[rowName][action.name] = isActive;
        })

        setNewRole({ ...newRole, permissions });
    }

    const checkIsRow = (rowName, isActive) => {
        let isRow = true;
        permissionActions.forEach((action) => {
            const group = permissionGroups.find(e => e.name === rowName);
            if (group.actions.includes(action.name)) {

                if (newRole.permissions[rowName] && newRole.permissions[rowName][action.name] !== isActive) {
                    isRow = false;
                }
                if (!newRole.permissions[rowName]) {
                    isRow = !isActive;
                }
            }
        })

        return isRow;
    }

    const handleClickColumn = (type, filter = []) => {
        if (type === 'blockAll' && !checkIsAll(false, filter)) {
            setAll(false, filter);
        } else if (type === 'blockAll') {
            setAll(true, filter);
        }

        if (type === 'fullAccess' && !checkIsAll(true, filter)) {
            setAll(true, filter);
        } else if (type === 'fullAccess') {
            setAll(false, filter);
        }

        if (type !== 'blockAll' && type !== 'fullAccess') {
            if (checkIsColumn(type, true, filter)) {
                setColumn(type, false, filter);
            } else {
                setColumn(type, true, filter);
            }
        }

    }

    const setColumn = (type, isActive, filter = []) => {
        const permissions = newRole.permissions;

        let permGroup = permissionGroups;
        if (filter.length) {
            permGroup = permGroup.filter(e => filter.includes(e.name));
        }

        permGroup.forEach((group) => {
            if (!permissions[group.name]) {
                permissions[group.name] = {};
            }
            permissions[group.name][type] = isActive;
        })

        setNewRole({ ...newRole, permissions });
    }

    const setAll = (isActive, filter = []) => {
        const permissions = newRole.permissions;

        let permGroup = permissionGroups;
        if (filter.length) {
            permGroup = permGroup.filter(e => filter.includes(e.name));
        }

        permGroup.forEach((group) => {
            permissionActions.forEach((action) => {
                if (!permissions[group.name]) {
                    permissions[group.name] = {};
                }
                permissions[group.name][action.name] = isActive;
            })
        })

        setNewRole({ ...newRole, permissions });
    }

    const checkIsColumn = (type, isActive, filter = []) => {
        let isColumn = true;

        let permGroup = permissionGroups;
        if (filter.length) {
            permGroup = permGroup.filter(e => filter.includes(e.name));
        }

        permGroup.forEach((group) => {
            if (group.actions.includes(type)) {

                if (newRole.permissions[group.name] && newRole.permissions[group.name][type] !== isActive) {
                    isColumn = false;
                }
                if (!newRole.permissions[group.name]) {
                    isColumn = !isActive;
                }
            }
        })
        return isColumn;
    }

    const checkIsAll = (isActive, filter = []) => {
        let isAll = true;

        let permGroup = permissionGroups;
        if (filter.length) {
            permGroup = permGroup.filter(e => filter.includes(e.name));
        }

        permGroup.forEach((group) => {
            permissionActions.forEach((action) => {
                if (group.actions.includes(action.name)) {

                    if (newRole.permissions[group.name] && newRole.permissions[group.name][action.name] !== isActive) {
                        isAll = false;
                    }
                    if (!newRole.permissions[group.name]) {
                        isAll = !isActive;
                    }
                }
            })
        })

        return isAll;
    }

    const handleSave = () => {
        if (!newRole.name.trim() ||
            (!isEdit && roles.some(r => r.name === newRole.name.trim())) ||
            (checkIsAll(false) && newRole.hasWebAccess) ||
            (!newRole.hasWebAccess && !newRole.hasMobileAccess)
        ) {
            setError(true);
            return;
        }
        onSubmit({ ...newRole, companyId: 1, name: newRole.name.trim() });
    }

    const viewMode = !newRole.editable
    const disableWebPermissions = !newRole.hasWebAccess;
    const isPermissionEmpty = newRole.hasWebAccess && checkIsAll(false);
    const hasNoPermission = !newRole.hasWebAccess && !newRole.hasMobileAccess;
    const nameError = error && (roles.some(r => r.name === newRole.name.trim()) || !newRole.name.trim());
    const nameHelperText = viewMode ? 'You cannot edit this role' 
                            : error && !newRole.name.trim() 
                            ? "Role name can't be empty" :
                            error && roles.some(r => r.name === newRole.name.trim()) ? 
                            'A role with this name already exists'  : ' ';

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xl" open={open}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={6}>
                        {!!role ? viewMode ? 'View Role' : 'Edit Role' : 'New Role'}
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <IconButton id="role-new-close" className={classes.closeIconBtn} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container>
                    <Grid container item lg={4} md={12} alignContent="flex-start">
                        <Grid item lg={8} md={6} className={classes.leftWrapper}>
                            <InputLabel id="role-field-name-label" required>Role Name</InputLabel>
                            <TextField
                                id="role-field-name"
                                variant="outlined"
                                value={newRole.name}
                                onChange={handleChange('name')}
                                error={nameError}
                                helperText={nameHelperText}
                                className={classes.input}
                                disabled={viewMode}
                            />
                        </Grid>
                        <Grid item lg={4} md={false} />
                        <Grid item lg={12} md={6}>
                            <FormGroup>
                                {additionalPermissions.map(permission => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={`role-field-${permission.name}`}
                                                color="primary"
                                                checked={permission.isReverse ? !newRole[permission.name] : !!newRole[permission.name]}
                                                onChange={handleCheckAdditional(permission.name)}
                                                disabled={viewMode}
                                                value="additionalPermission"
                                            />}
                                        id={`role-field-${permission.name}-label`}
                                        label={permission.desc}
                                        key={permission.name}
                                        classes={{
                                            label: classes.label,
                                        }}
                                    />
                                ))}
                            </FormGroup>
                            <p id="role-field-message-web-or-mobile-must-granted" className={classes.errorText}>
                                {hasNoPermission && 'Either web or mobile access must be granted.'}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid container item lg={8} md={12}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.tblHeadRow}>
                                    <TableCell className={classes.tCell}>Access areas</TableCell>
                                    <TableCell
                                        id="role-new-permission-toggle-all"
                                        align="center"
                                        className={checkIsAll(false) ? classes.blockedAllActive : classes.blockedAll}
                                        onClick={() => viewMode || disableWebPermissions ? {} : handleClickColumn('blockAll')}
                                    >
                                        <BlockIcon />
                                        <span className={classes.smallAll}>All</span>
                                    </TableCell>
                                    <TableCell
                                        id="role-new-permission-toggle-add-all"
                                        align="center"
                                        className={classes.tCell}
                                        className={checkIsColumn('add', true) ? classes.selectAllActive : classes.selectAll}
                                        onClick={() => viewMode || disableWebPermissions ? {} : handleClickColumn('add')}
                                    >
                                        <AddIcon />
                                        <span className={classes.smallAll}>All</span>
                                    </TableCell>
                                    <TableCell
                                        id="role-new-permission-toggle-edit-all"
                                        align="center"
                                        className={classes.tCell}
                                        className={checkIsColumn('edit', true) ? classes.selectAllActive : classes.selectAll}
                                        onClick={() => viewMode || disableWebPermissions ? {} : handleClickColumn('edit')}
                                    >
                                        <EditIcon />
                                        <span className={classes.smallAll}>All</span>
                                    </TableCell>
                                    <TableCell
                                        id="role-new-permission-toggle-remove-all"
                                        align="center"
                                        className={classes.tCell}
                                        className={checkIsColumn('remove', true) ? classes.selectAllActive : classes.selectAll}
                                        onClick={() => viewMode || disableWebPermissions ? {} : handleClickColumn('remove')}
                                    >
                                        <RemoveIcon />
                                        <span className={classes.smallAll}>All</span>
                                    </TableCell>
                                    <TableCell
                                        id="role-new-permission-toggle-view-all"
                                        align="center"
                                        className={classes.tCell}
                                        className={checkIsColumn('view', true) ? classes.selectAllActive : classes.selectAll}
                                        onClick={() => viewMode || disableWebPermissions ? {} : handleClickColumn('view')}
                                    >
                                        <ViewIcon />
                                        <span className={classes.smallAll}>All</span>
                                    </TableCell>
                                    <TableCell
                                        id="role-new-permission-toggle-upload-download-all"
                                        align="center"
                                        className={classes.tCell}
                                        className={checkIsColumn('uploadDownload', true) ? classes.selectAllActive : classes.selectAll}
                                        onClick={() => viewMode || disableWebPermissions ? {} : handleClickColumn('uploadDownload')}
                                    >
                                        <UploadDownloadIcon />
                                        <span className={classes.smallAll}>All</span>
                                    </TableCell>
                                    <TableCell
                                        id="role-new-permission-toggle-full-access-all"
                                        align="center"
                                        className={classes.tCel}
                                        className={checkIsAll(true) ? classes.fullAccessActive : classes.fullAccess}
                                        onClick={() => viewMode || disableWebPermissions ? {} : handleClickColumn('fullAccess')}
                                    >
                                        <FullAccessIcon />
                                        <span className={classes.smallAll}>All</span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className={classes.groupRow}>
                                    <TableCell className={classes.rowHeader} component="th" scope="row">Admin Permissions</TableCell>
                                    <TableCell className={classes.tCell} align="center">
                                        <Button
                                            id="role-new-permission-toggle-block-all-admin"
                                            className={checkIsAll(false, adminRoles) ? classes.btnBlockAllActive : classes.btnBlockAll}
                                            onClick={() => handleClickColumn('blockAll', adminRoles)}
                                            disabled={viewMode || disableWebPermissions}
                                        >
                                            Block All
                                        </Button>
                                    </TableCell>
                                    {permissionActions.map((action, index2) => (
                                        <TableCell className={classes.tCell} align="center" key={`group-permission-row-${index2}`}>
                                            <Button
                                                id={`role-new-permission-toggle-action-admin-${index2}`}
                                                className={checkIsColumn(action.name, true, adminRoles) ? classes.btnActive : classes.btnDefault}
                                                onClick={() => handleClickColumn(action.name, adminRoles)}
                                                style={adminActionsFilter.includes(action.name) ? { display: 'none' } : {}}
                                                disabled={viewMode || disableWebPermissions}
                                            >
                                                {action.label}
                                            </Button>
                                        </TableCell>
                                    ))}
                                    <TableCell className={classes.tCell} align="center">
                                        <Button
                                            id="role-new-permission-toggle-full-access-admin"
                                            className={checkIsAll(true, adminRoles) ? classes.btnFullAccessActive : classes.btnFullAccess}
                                            onClick={() => handleClickColumn('fullAccess', adminRoles)}
                                            disabled={viewMode || disableWebPermissions}
                                        >
                                            Full Access
                                            </Button>
                                    </TableCell>
                                </TableRow>
                                {permissionGroups.filter(e => adminRoles.includes(e.name)).map((group, index) => (
                                    <TableRow key={`permission-row-${index}`}>
                                        <TableCell className={classes.rowHeader2} component="th" scope="row">{group.label}</TableCell>
                                        <TableCell className={classes.tCell} align="center">
                                            <Button
                                                id={`role-new-permission-toggle-block-all-admin-${index}`}
                                                className={checkIsRow(group.name, false) ? classes.btnBlockAllActive : classes.btnBlockAll}
                                                onClick={handleClickCell(group.name, 'blockAll')}
                                                disabled={viewMode || disableWebPermissions}
                                            >
                                                Block All
                                            </Button>
                                        </TableCell>
                                        {permissionActions.map((action, index2) => (
                                            <TableCell className={classes.tCell} align="center" key={`permission-row-${index}-${index2}`}>
                                                <Button
                                                    id={`role-new-permission-toggle-action-admin-${index}-${index2}`}
                                                    className={checkIsCell(group.name, action.name, true) ? classes.btnActive : classes.btnDefault}
                                                    onClick={handleClickCell(group.name, action.name)}
                                                    style={group.actions.includes(action.name) ? {} : { display: 'none' }}
                                                    disabled={viewMode || disableWebPermissions}
                                                >
                                                    {action.label}
                                                </Button>
                                            </TableCell>
                                        ))}
                                        <TableCell className={classes.tCell} align="center">
                                            <Button
                                                id={`role-new-permission-toggle-full-access-admin-${index}`}
                                                className={checkIsRow(group.name, true) ? classes.btnFullAccessActive : classes.btnFullAccess}
                                                onClick={handleClickCell(group.name, 'fullAccess')}
                                                disabled={viewMode || disableWebPermissions}
                                            >
                                                Full Access
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow className={classes.groupRow}>
                                    <TableCell className={classes.tCell} className={classes.rowHeader} component="th" scope="row">Clinical Permissions</TableCell>
                                    <TableCell className={classes.tCell} align="center">
                                        <Button
                                            id="role-new-permission-toggle-block-all-clinical"
                                            className={checkIsAll(false, clinicalsRoles) ? classes.btnBlockAllActive : classes.btnBlockAll}
                                            onClick={() => handleClickColumn('blockAll', clinicalsRoles)}
                                            disabled={viewMode || disableWebPermissions}
                                        >
                                            Block All
                                            </Button>
                                    </TableCell>
                                    {permissionActions.map((action, index2) => (
                                        <TableCell className={classes.tCell} align="center" key={`group-permission-row-${index2}`}>
                                            <Button
                                                id={`role-new-permission-toggle-action-clinical-${index2}`}
                                                className={checkIsColumn(action.name, true, clinicalsRoles) ? classes.btnActive : classes.btnDefault}
                                                onClick={() => handleClickColumn(action.name, clinicalsRoles)}
                                                disabled={viewMode || disableWebPermissions}
                                            >
                                                {action.label}
                                            </Button>
                                        </TableCell>
                                    ))}
                                    <TableCell className={classes.tCell} align="center">
                                        <Button
                                            id="role-new-permission-toggle-full-access-clinical"
                                            className={checkIsAll(true, clinicalsRoles) ? classes.btnFullAccessActive : classes.btnFullAccess}
                                            onClick={() => handleClickColumn('fullAccess', clinicalsRoles)}
                                            disabled={viewMode || disableWebPermissions}
                                        >
                                            Full Access
                                            </Button>
                                    </TableCell>
                                </TableRow>
                                {permissionGroups.filter(e => clinicalsRoles.includes(e.name)).map((group, index) => (
                                    <TableRow key={`permission-row-${index}`}>
                                        <TableCell className={classes.rowHeader2} component="th" scope="row">{group.label}</TableCell>
                                        <TableCell className={classes.tCell} align="center">
                                            <Button
                                                id={`role-new-permission-toggle-action-clinical-${index}`}
                                                className={checkIsRow(group.name, false) ? classes.btnBlockAllActive : classes.btnBlockAll}
                                                onClick={handleClickCell(group.name, 'blockAll')}
                                                disabled={viewMode || disableWebPermissions}
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                Block All
                                            </Button>
                                        </TableCell>
                                        {permissionActions.map((action, index2) => (
                                            <TableCell className={classes.tCell} align="center" key={`permission-row-${index}-${index2}`}>
                                                <Button
                                                    id={`role-new-permission-toggle-action-clinical-${index}-${index2}`}
                                                    className={checkIsCell(group.name, action.name, true) ? classes.btnActive : classes.btnDefault}
                                                    onClick={handleClickCell(group.name, action.name)}
                                                    style={group.actions.includes(action.name) ? {} : { display: 'none' }}
                                                    disabled={viewMode || disableWebPermissions}
                                                >
                                                    {action.label}
                                                </Button>
                                            </TableCell>
                                        ))}
                                        <TableCell className={classes.tCell} align="center">
                                            <Button
                                                id={`role-new-permission-toggle-full-access-clinical-${index}`}
                                                className={checkIsRow(group.name, true) ? classes.btnFullAccessActive : classes.btnFullAccess}
                                                onClick={handleClickCell(group.name, 'fullAccess')}
                                                disabled={viewMode || disableWebPermissions}
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                Full Access
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell id={`role-table-message-${isPermissionEmpty ? 'one-must-active' : 'no-access'}`} style={{ visibility: disableWebPermissions || isPermissionEmpty ? 'visible' : 'hidden' }} className={classes.tCellWarning} colSpan={9}>
                                        {isPermissionEmpty ? "At least one permission must be active." : "This role doesn't have access to web application."}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Grid container>
                    <Grid item xs={6}>
                        <Button id="role-new-cancel" className={classes.saveBtn} onClick={handleCancel}>{viewMode ? 'Close' : 'Cancel'}</Button>
                    </Grid>
                    {!viewMode &&
                        <Grid id="role-new-save" item xs={6} container justify="flex-end">
                            <Button className={classes.saveBtn} onClick={handleSave} disabled={loading.newRole || loading.editRole}>Save</Button>
                        </Grid>
                    }
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

NewRole.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool,
    role: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    loading: state.general.loading,
})

export default connect(mapStateToProps)(NewRole)
